<template>
  <div>
    <template v-for="(item, index) in moData">
      <!-- {{ index }} -->
      <!-- <lazy-component> -->

      <moHots
        v-if="item.ele.en == 'hots'"
        :key="item.id"
        :id="item.id"
        @defaultFoo="defaultFoo"
        :moData="item"
      ></moHots>

      <!-- </lazy-component> -->

      <moCollapse
        :id="item.id"
        v-if="item.ele.en == 'collapse'"
        :key="item.id"
        :moData="item"
        @defaultFoo="defaultFoo"
      ></moCollapse>

      <!-- <lazy-component> -->

      <moIcon
        :id="item.id"
        v-if="item.ele.en == 'icon'"
        :key="item.id"
        :moData="item"
        @defaultFoo="defaultFoo"
      ></moIcon>

      <!-- </lazy-component> -->

      <moVideo
        :id="item.id"
        v-if="item.ele.en == 'video'"
        :key="item.id"
        @defaultFoo="defaultFoo"
        :moData="item"
      ></moVideo>

      <moSwiper
        :id="item.id"
        v-if="item.ele.en == 'swiper'"
        :key="item.id"
        :moData="item"
        @defaultFoo="defaultFoo"
      ></moSwiper>

      <!-- <lazy-component> -->

      <moImage
        :id="item.id"
        v-if="item.ele.en == 'image'"
        :key="item.id"
        :moData="item"
        @defaultFoo="defaultFoo"
      ></moImage>

      <!-- </lazy-component> -->

      <moCountdown
        :id="item.id"
        v-if="item.ele.en == 'countdown'"
        :key="item.id"
        :moData="item"
        @defaultFoo="defaultFoo"
      ></moCountdown>

      <moSearch
        :id="item.id"
        v-if="item.ele.en == 'search'"
        :key="item.id"
        :moData="item"
        @defaultFoo="defaultFoo"
      ></moSearch>

      <moArea
        :id="item.id"
        v-if="item.ele.en == 'area'"
        :key="item.id"
        :moData="item"
        @defaultFoo="defaultFoo"
      ></moArea>

      <!-- <lazy-component> -->
      <mo-two-img
        :id="item.id"
        v-if="item.ele.en == 'twoImg'"
        :key="item.id"
        :moData="item"
        @defaultFoo="defaultFoo"
      ></mo-two-img>

      <!-- </lazy-component> -->

      <moSolid
        :id="item.id"
        v-if="item.ele.en == 'solid'"
        :key="item.id"
        :moData="item"
        @defaultFoo="defaultFoo"
      ></moSolid>

      <moNews
        :id="item.id"
        v-if="item.ele.en == 'news'"
        :key="item.id"
        :moData="item"
        @defaultFoo="defaultFoo"
      ></moNews>

      <moNotice
        :id="item.id"
        v-if="item.ele.en == 'notice'"
        :key="item.id"
        :moData="item"
        @defaultFoo="defaultFoo"
      ></moNotice>

      <moTabs
        :id="item.id"
        v-if="item.ele.en == 'tabs'"
        :key="item.id"
        :moData="item"
        @defaultFoo="defaultFoo"
      ></moTabs>

      <moLift
        :id="item.id"
        v-if="item.ele.en == 'lift'"
        :key="item.id"
        :moData="item"
        @defaultFoo="defaultFoo"
      ></moLift>

      <moVision
        :id="item.id"
        v-if="item.ele.en == 'vision'"
        :key="item.id"
        :moData="item"
        :i="index"
        @defaultFoo="defaultFoo"
      ></moVision>

      <moText
        :id="item.id"
        v-if="item.ele.en == 'text'"
        :key="item.id"
        :moData="item"
        :i="index"
        @defaultFoo="defaultFoo"
      ></moText>
      <moZoomSwiper
        :id="item.id"
        v-if="item.ele.en == 'zoomSwiper'"
        :key="item.id"
        :moData="item"
        :i="index"
        @defaultFoo="defaultFoo"
      ></moZoomSwiper>
      <moListData
        :id="item.id"
        v-if="item.ele.en == 'listData'"
        :key="item.id"
        :moData="item"
        :i="index"
        @defaultFoo="defaultFoo"
      ></moListData>

      <moXiaoetStudyList
        :id="item.id"
        v-if="item.ele.en == 'xiaoet-studyList'"
        :key="item.id"
        :moData="item"
        :i="index"
        @defaultFoo="defaultFoo"
      ></moXiaoetStudyList>

      <moXiaoetLive
        :id="item.id"
        v-if="item.ele.en == 'xiaoet-live'"
        :key="item.id"
        :moData="item"
        :i="index"
        @defaultFoo="defaultFoo"
      ></moXiaoetLive>
      <!-- <moRaceSwiper :id="item.id" :key="item.id" :moData="item" :i="index"></moRaceSwiper> -->
    </template>

    <oChat :boo.sync="chatBoo" v-if="moData && moData.length != 0"></oChat>

    <van-action-sheet
      v-model="addtinalShow"
      :actions="addtionalActions"
      :description="addtionalDes"
      @select="addtinalSelect"
      cancel-text="取消"
      close-on-click-action
    />
  </div>
</template>

<script>
import moHots from "../hots";
import moListData from "../listData";
import moCollapse from "../collapse";
import moVideo from "../video";
import moSwiper from "../swiper";
import moImage from "../image";
import moSearch from "../search";
import moIcon from "../icon";
import moSolid from "../solid";
import moArea from "../area";
import moTwoImg from "../twoImg";
import moNews from "../news";
import moNotice from "../notice";
import moTabs from "../tabs";
import moVision from "../vision";
import moLift from "../lift";
import moText from "../text";
import moXiaoetStudyList from "../xiaoet-studyList";
import moXiaoetLive from "../xiaoet-live";
import moZoomSwiper from "../zoomSwiper";
// import moRaceSwiper from "../raceSwiper";
import moCountdown from "../countdown";
import oChat from "@/view/me/view/chat/popup.vue";
import { ActionSheet } from "vant";

export default {
  name: "tree",
  data() {
    return {
      chatBoo: false,
      addtinalShow: false,
      addtionalDes: "",
      addtionalActions: [],
    };
  },
  props: {
    moData: {},
  },
  methods: {
    addtinalSelect(data) {
      console.log(data);
      this.$router.push({
        query: {
          goods_id: data.goods_id,
        },
        name: "HomeGoodsdetail",
      });
    },
    defaultFoo(data) {
      if (data.type == "chat") {
        this.chatBoo = true;
      }
      if (data.type == "addtional") {
        let arr = [];
        this.addtionalDes = data.message;
        for (let index = 0; index < data.jump.length; index++) {
          arr[index] = {
            name: data.jump[index]["goods_name"],
            goods_id: data.jump[index]["goods_id"],
            subname: "立即购买",
          };
        }
        this.addtionalActions = arr;

        this.addtinalShow = true;
      }
    },
  },
  components: {
    // moRaceSwiper,
    vanActionSheet: ActionSheet,
    moXiaoetStudyList,
    oChat,
    moCollapse,
    moZoomSwiper,
    moCountdown,
    moHots,
    moListData,
    moVideo,
    moText,
    moIcon,
    moSwiper,
    moImage,
    moSearch,
    moArea,
    moTwoImg,
    moSolid,
    moNews,
    moNotice,
    moTabs,
    moVision,
    moLift,
    moXiaoetLive,
  },
};
</script>

<style lang="scss" scoped></style>
