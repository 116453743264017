export const toHref = (url) => {
  window.location.href = url
}
export const imgLocation = (url) => {
  return process.env.NODE_ENV === 'production' ? 'https://shop.19jiang.cn' + url : url
}


export const sizeChange = ($px, fontSize) => {
  return String($px / (75)) + fontSize;
}