<template>
  <div class="hots">
    <img :src="moData.img" class="hots_bg" />
    <template v-for="(item, index) in moData.hots">
      <i
        :key="index"
        @click="getModelPath(item.url)"
        class="hots_area"
        :style="{
          top: item.topPer * 100 + '%',
          left: item.leftPer * 100 + '%',
          width: item.widthPer * 100 + '%',
          height: item.heightPer * 100 + '%',
        }"
      >
      </i>
    </template>
  </div>
</template>
<script>
import { setRem, getModelPath, getModelIdVal } from "../../js";
export default {
  name: "",
  props: {
    moData: {},
  },
  data: () => ({}),
  created() {
    // console.log(this.moData);
  },
  methods: {
    getModelPath(data) {
      getModelPath(data, this);
    },
    getModelIdVal,
    setRem,
  },
};
</script>
<style lang="scss" scoped>
.hots {
  position: relative;
  overflow: hidden;
  .hots_area {
    position: absolute;
    z-index: 99;
    // background: #000
  }
}
.hots_bg {
  width: 100%;
  display: block;
}
</style>
