<template>
  <div v-if="image_list && image_list.length != 0">
    <div class="flex-container2 title2">
      <img src="../../../../../../assets/image/order/20211109-183021.gif" />
      <oTitle :title="image_title" :ismore="false" class="all-title"></oTitle>
    </div>
    <div id="tab-ads" v-if="image_list && image_list.length != 0">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in image_list" :key="index">
          <img
            :src="item.image_url"
            @click="goHref(item.location_url)"
            style="width:100%"
          />
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>

<script>
import { Swipe, SwipeItem } from "vant";
import oTitle from "../title.vue";
import { get } from "@get/http";
export default {
  components: {
    vanSwipe: Swipe,
    vanSwipeItem: SwipeItem,
    oTitle,
  },
  data() {
    return {
      image_list: [],
      image_title: "",
    };
  },
  methods: {
    goHref(src) {
      if (src) {
        window.location.href = src;
      }
    },
  },
  async created() {
    let { image_list, image_title } = await get("/GoodsArticle/getImage", {
      params: {
        type: 4,
      },
    });
    this.image_list = image_list;
    this.image_title = image_title;
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.all-title {
  padding: rem(30) rem(30) 0;
  margin-bottom: rem(30);
}
#tab-ads {
  margin-bottom: rem(24);
  // margin-top: rem(40);
  // display: flex;
  // overflow-y: hidden;
  .my-swipe {
    width: rem(690);
    // height: rem(160);
    margin: 0 auto;
    img {
      display: inline-block;
      width: 100%;
      height: 100%;
      // margin-right: rem(16);

      border-radius: rem(16);
    }
  }
}

.flex-container2 {
  display: flex;
}
.title2 {
  align-items: center;
  > img {
    margin-left: rem(30);
    width: rem(30);
    height: rem(30);
  }
}
</style>
