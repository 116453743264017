var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"place_area mo-place"},[(_vm.getModelIdVal(_vm.moData, 'mode') == 1)?_c('div',{staticClass:"area-box x",style:({
      height: _vm.size(_vm.getModelIdVal(_vm.moData, 'height')),
      background: _vm.getModelIdVal(_vm.moData, 'img')
        ? ("url('" + (_vm.getModelIdVal(_vm.moData, 'img')) + "') no-repeat")
        : _vm.getModelIdVal(_vm.moData, 'bgcolor'),
      backgroundSize: '100% ' + _vm.size(_vm.getModelIdVal(_vm.moData, 'height')),
    })},[_vm._l((_vm.moData.swiper.length == 0 ? 1 : _vm.moData.swiper),function(items,i){return [_c('img',{key:i,style:({
          width: _vm.size(_vm.moData.swiper[i].width),
          height: _vm.size(_vm.moData.swiper[i].height),
          marginTop: _vm.size(_vm.getModelIdVal(_vm.moData, 'inside')),
        }),attrs:{"src":items && items.link},on:{"click":function($event){return _vm.getModelPath(items)}}})]})],2):(_vm.getModelIdVal(_vm.moData, 'mode') == 2)?_c('div',{staticClass:"area-box y",style:({
      height: _vm.getModelIdVal(_vm.moData, 'height') / 2 + 'px',
    })},[_vm._l((_vm.moData.swiper.length == 0 ? 1 : _vm.moData.swiper),function(items,ii){return [_c('img',{key:ii,staticStyle:{"width":"100%"},attrs:{"src":items && items.link},on:{"click":function($event){return _vm.getModelPath(items)}}})]})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }