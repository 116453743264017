<template>
  <div id="tab-study">
    <!-- <oLive></oLive> -->
    <!-- <oFree></oFree> -->
    <oAds></oAds>
    <oCourse></oCourse>
    <oClass istitle></oClass>
    <oOffline></oOffline>
  </div>
</template>

<script>
import oLive from "./live/live.vue";
import oFree from "./free/free.vue";
import oAds from "./ads.vue";
import oCourse from "./course.vue";
import oClass from "./class/class.vue";
import oOffline from "./offline/offline.vue";
export default {
  components: {
    // oLive,
    oAds,
    // oFree,
    oCourse,
    oClass,
    oOffline,
  },
  data() {
    return {
      typeList: [],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
#tab-study {
  // padding: 0 rem(30);
  .study-title {
    padding: rem(32) 0 rem(24);
  }
}
</style>
