import {
  post
} from '@get/http'

import {
  Toast
} from 'vant'

const setRem = ($px) => {
  /**
   *  @method setRem   px转rem
   *  @param {string | number} $px 像素px
   *  @return {string} 返回转换过的值
   */

  return $px / (75) + 'rem'
}





const getModelPath = async (data, that) => {

  /***
   *  @method getModelPath 获取模块的跳转链接
   *  @param {object} data 模块点击列表
   *  @param {object} that 模块对象
   *  @return {void}
   * 
   */

  if (data.list) {
    if (data?.propsJson?.modelMenu == '加购') {
      let res = await post('/v3.memberMultiSelectGoods/checkPurchasedGoods', {
        data: {
          goods_id: data.list[0].goods_id
        }
      }, {
        toast: false,
        result: true,
        resultKey: 'all'
      })

      if (res.code == 10000) {
        that.$router.push({
          "name": "Buy_Multiple",
          query: {
            cart_id: `${data.list[0].goods_id}|1`,
            type: 1
          }
        })
      } else {

        // Dialog
        if (res?.result?.jump) {
          that.$emit('defaultFoo', {
            type: 'addtional',
            jump: res?.result?.jump,
            message: res.message
          })
        } else {
          Toast(res.message + '，如有疑问请咨询客服。');
        }

      }
    } else if (data.list[0].modeType == 'coupon') {
      Toast.loading({
        message: '领取中',
        forbidClick: true,
        duration: 0
      });
      //兑换优惠券
      try {
        await post('/Membervoucher/voucher_point', {
          data: {
            tid: data.list[0].goods_id
          }
        })
        Toast({
          message: '领取成功',
          forbidClick: true,
          duration: 1000
        });
      } catch (error) {
        Toast.clear();
      }



    } else {
      that.$router.push({
        "name": "HomeGoodsdetail",
        query: {
          goods_id: data.list[0].goods_id
        }
      })
    }

  } else if (data.tips == '新人礼' || data.tips == '体验礼') {
    Toast.loading({
      message: '领取中',
      forbidClick: true,
      duration: 0
    });
    try {
      if (data.name == 'getWelfare') {
        //新人礼
        await post('/lw.MemberWelfare/getWelfare')
      } else if (data.name == 'getTasteWelfare') {
        await post('/lw.MemberWelfare/getTasteWelfare')
      }
      Toast({
        message: '领取成功',
        forbidClick: true,
        duration: 1000
      });
    } catch (error) {
      Toast.clear();
    };
  } else if (data.name == '电梯') {

    let windowTop = document.documentElement.scrollTop || document.body.scrollTop;
    let _currentY = document.getElementById(data.href || data.url).offsetTop - windowTop;
    window.scrollBy({
      top: _currentY
    });

  } else if (data.name == 'chat') {
    that.$emit("defaultFoo", {
      type: 'chat',
      data: {}
    })
  } else {
    if (data.name && data.name.length != 0) {
      that.$router.push({
        "name": data.name
      })
    } else {
      if (data.href) {
        window.location.href = data.href;
        return false;
      }
      if (data.url) {
        window.location.href = data.url;
        return false;
      }

    }
    that.$router.push({
      "name": data.name,
      query: {
        ...that.$route.query
      }
    })

  }
}


const getModelIdVal = (data, id, mode = 'contorl') => {


  /***
   * @method getIdVal //获取指定模块ID的值
   * @param {object} data 模块数据
   * @param {number} id 需要取出的模块ID
   * @param {stirng} [mode='contorl'] 需要从模块的哪个键值取出对应ID的数据
   * @return {string} 返回查询得到的值  || ""
   */


  let state = data[`${mode}`];

  if (!state) {
    return ""
  } else {
    for (var i = 0; i < state.length; i++) {
      if (state[i].id == id) {

        if (state[i].id == "bgcolor" ||
          state[i].id == "color" ||
          state[i].id == "descolor" ||
          state[i].id == "iconColor" ||
          state[i].id == "img" ||
          state[i].id == "text" ||
          state[i].id == "beforeimg" ||
          state[i].id == "bgimg" ||
          state[i].id == "tipImgRight" ||
          state[i].id == "tipImgLeft" ||
          state[i].id == "searchBtnColor" ||
          state[i].id == "borderColor" ||

          state[i].id == "endimg") {

          return state[i].val

        } else {
          return Number(state[i].val);
        }


      }
    }
  }

}



export {
  setRem,
  getModelPath,
  getModelIdVal

}