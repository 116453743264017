<template>
  <div
    :style="{
      padding: setRem(getModelIdVal(moData, 'outside')) + ' 0',
      background: getModelIdVal(moData, 'bgcolor'),
    }"
  >
    <template>
      <!-- "padding" :  setRem(getModelIdVal(moData,"outside")) + " 0" -->
      <!-- {{moData}} -->
      <!-- :auto="getModelIdVal(moData,'speed')" -->

      <van-swipe
        :autoplay="getModelIdVal(moData, 'speed')"
        :show-indicators="false"
        :vertical="getModelIdVal(moData, 'move') == 2 ? true : false"
        :style="{
          color: getModelIdVal(moData, 'color'),
          height: getModelIdVal(moData, 'row') == 2 ? setRem(100) : setRem(50),
        }"
      >
        <van-swipe-item
          v-for="(item, index) in moData.txt"
          :key="index"
          :style="{
            height:
              getModelIdVal(moData, 'row') == 2 ? setRem(100) : setRem(50),
          }"
          class="flex-container"
          v-if="item.val.length != 0"
        >
          <p
            :class="getModelIdVal(moData, 'row') == 2 ? 'wrap2' : 'wrap1'"
            :style="{
              'font-size': setRem(getModelIdVal(moData, 'size') * 2),
              color: getModelIdVal(moData, 'color'),
            }"
          >
            {{ item.val }}
          </p>
        </van-swipe-item>
      </van-swipe>
    </template>
  </div>
</template>

<script>
import { setRem, getModelPath, getModelIdVal } from "../../js";
import { Swipe, SwipeItem } from "vant";
export default {
  name: "",
  data: () => ({
    //Image list
    someList: [],
  }),
  components: {
    vanSwipe: Swipe,
    vanSwipeItem: SwipeItem,
  },
  props: {
    moData: {},
  },

  created() {
    //do something after creating vue instance
    // console.log(this.moData);
  },
  methods: {
    getModelPath(data) {
      getModelPath(data, this);
    },
    getModelIdVal,
    setRem,
  },
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";
.wrap1 {
  // height: 30px;
  @include ell;
  padding: 0 rem(26);
}
.wrap2 {
  // height: 60px;
  @include ell2;
  word-break: break-all;
  padding: 0 rem(26);
}

.flex-container {
  align-items: center;
}
</style>
