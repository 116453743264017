<template>
  <div class="index">
    <moModel :moData="moData"></moModel>
    <oAds></oAds>
  </div>
</template>

<script>
import { Button } from "vant";
import { post } from "@get/http.js";
import moModel from "./components/models";
import oAds from "./view/ads/index.vue";
import { getQueryVariable } from "@get/util";

export default {
  name: "HomeIndex",
  components: {
    vanButton: Button,
    moModel,
    oAds,
  },
  data() {
    return {
      moData: [],
    };
  },
  created() {
    this.getIndexModelData();
  },
  methods: {
    async getIndexModelData() {
      /***
       * @method getIndexModelData 获取首页模块数据
       */

      try {
        let store_id = 2;
        let id = 49;
        let page_type = 1;

        let { page_config } = await post("/index/mofang", {
          data: {
            store_id,
            id,
            page_type,
            inviter_level:
              this.$store.state?.user?.info["19jiang_inviter_level_id"] || 0,
          },
        });
        this.moData = page_config;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss">
body,
html {
  // overflow-x: hidden;
}
.index {
  margin-bottom: 70px;
}
</style>
