var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('van-overlay',{attrs:{"show":_vm.show,"close-on-click-overlay":false,"z-index":"1001"}},[_c('div',{staticClass:"wrapper",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"popupImg flex-container"},[_c('img',{ref:"img",staticClass:"t_img",style:({
          bottom: _vm.size.bottom + '%',
          right: _vm.size.right + '%',
          width: _vm.size.width + '%',
          height: _vm.size.height + '%',
        }),attrs:{"src":_vm.imgLocation(_vm.list.image)},on:{"click":function($event){return _vm.toHref(_vm.list.link)}}}),_c('div',{staticClass:"error_div",style:({
          width: _vm.size.error_width + '%',
          height: _vm.size.error_height + '%',
          top: _vm.rem(_vm.size.error_top),
          left: _vm.rem(_vm.size.error_left),
        }),on:{"click":function($event){_vm.show = false}}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }