var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    padding: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'outside')) + ' 0',
    background: _vm.getModelIdVal(_vm.moData, 'bgcolor'),
  })},[[_c('van-swipe',{style:({
        color: _vm.getModelIdVal(_vm.moData, 'color'),
        height: _vm.getModelIdVal(_vm.moData, 'row') == 2 ? _vm.setRem(100) : _vm.setRem(50),
      }),attrs:{"autoplay":_vm.getModelIdVal(_vm.moData, 'speed'),"show-indicators":false,"vertical":_vm.getModelIdVal(_vm.moData, 'move') == 2 ? true : false}},_vm._l((_vm.moData.txt),function(item,index){return (item.val.length != 0)?_c('van-swipe-item',{key:index,staticClass:"flex-container",style:({
          height:
            _vm.getModelIdVal(_vm.moData, 'row') == 2 ? _vm.setRem(100) : _vm.setRem(50),
        })},[_c('p',{class:_vm.getModelIdVal(_vm.moData, 'row') == 2 ? 'wrap2' : 'wrap1',style:({
            'font-size': _vm.setRem(_vm.getModelIdVal(_vm.moData, 'size') * 2),
            color: _vm.getModelIdVal(_vm.moData, 'color'),
          })},[_vm._v(" "+_vm._s(item.val)+" ")])]):_vm._e()}),1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }