<template>
  <van-overlay :show="show" :close-on-click-overlay="false" z-index="1001">
    <div class="wrapper" @click.stop>
      <div class="popupImg flex-container">
        <img
          @click="toHref(list.link)"
          :src="imgLocation(list.image)"
          class="t_img"
          ref="img"
          :style="{
            bottom: size.bottom + '%',
            right: size.right + '%',
            width: size.width + '%',
            height: size.height + '%',
          }"
        />
        <div
          class="error_div"
          :style="{
            width: size.error_width + '%',
            height: size.error_height + '%',
            top: rem(size.error_top),
            left: rem(size.error_left),
          }"
          @click="show = false"
        ></div>
        <!-- <img src="https://shop.19jiang.cn/h5/source/img/shop/error-f.png" class='error'  @click='show = false'> -->
      </div>
    </div>
  </van-overlay>
</template>
<script>
import { Overlay } from "vant";
import { toHref, imgLocation } from "./index.js";
export default {
  props: ["list"],
  data: () => ({
    show: true,
    fontSize: "%",
    size: {
      bottom: "10%",
      right: "0%",
      width: "70%",
      height: "auto",
    },
  }),
  components: {
    vanOverlay: Overlay,
  },
  created() {
    let isShow = sessionStorage["popup_" + this.list.id + ""] || false;

    if (this.list.image_config == null || this.list.image_config.length == 0) {
      // 预留
    } else {
      for (let val in this.list.image_config) {
        this.size[val] = this.list.image_config[val];
      }
    }

    if (isShow) {
      this.show = false;
      return false;
    }

    sessionStorage["popup_" + this.list.id + ""] = "true";
  },
  methods: {
    rem($px) {
      return $px / 75 + "rem";
    },
    toHref,
    imgLocation,
  },
};
</script>
<style lang="scss" scoped>
/*flex*/
.flex-container {
  display: -moz-box;
  /* Firefox */
  display: -ms-flexbox;
  /* IE10 */
  display: -webkit-box;
  /* Safari */
  display: -webkit-flex;
  /* Chrome, WebKit */
  display: flexbox;
  display: flex;
}

.wrapper {
  .popupImg {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    // bottom: 0;
    // right: 0;
    // margin: auto;
    // flex-direction: column;

    // justify-content: center;
    .t_img {
      // margin-top: 70px;
      display: block;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
    .error {
      width: 30px;
      display: block;
      margin: 40px auto 0;
    }
    .error_div {
      position: absolute;
      z-index: 9999999;
      bottom: 0;
      right: 0;
      top: 0;
      left: 0;
      margin: auto;
    }
  }
}
</style>
