<template>
  <div class="course-div">
    <ul class="course-ul">
      <template>
        <li
          class="course-ul-li"
          v-for="item in list"
          :key="item.id"
          v-if="item.status == 1"
          @click="goHref(item.link)"
        >
          <img :src="item.cover_img" class="mainImg" />
          <div class="info">
            <div class="info-title">
              <h1>{{ item.title }}</h1>
              <span class="sub">{{ item.subtitle }}</span>
            </div>
            <div class="data">
              <p class="price">
                <i v-if="item.price && item.price != '0.00'">￥</i
                ><b>{{
                  item.price && item.price != "0.00" ? item.price : "免费"
                }}</b>
                <span v-if="item.marketprice != '0.00'"
                  >￥{{ item.marketprice }}</span
                >
              </p>
              <span class="on">{{ item.open }}人开通</span>
            </div>
          </div>
        </li>
      </template>
      <van-divider>暂无更多</van-divider>
      <!-- <template v-if="list && list.length == 0">
        <van-divider>暂无课程</van-divider>
      </template> -->
    </ul>
  </div>
</template>

<script>
import { Divider } from "vant";
export default {
  components: {
    vanDivider: Divider,
  },
  props: {
    list: {},
  },
  methods: {
    goHref(src) {
      if (src) {
        window.location.href = src;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
::v-deep {
  .van-divider {
    padding-bottom: rem(30);
  }
}
.course-div {
  width: rem(690);
  // margin: rem(20) auto 0;
  border-radius: rem(16);
  background: #fff;
  box-sizing: border-box;
  padding: 0 rem(24);
}
.course-ul {
}
.course-ul-li {
  display: flex;
  // border-radius: rem(16);
  padding-top: rem(24);
  background: #fff;
  &:last-of-type {
    padding-bottom: rem(24);
  }
  box-sizing: border-box;

  .mainImg {
    border-radius: rem(8);
    width: rem(224);
    height: rem(168);
  }
  .info {
    margin-left: rem(24);
    width: rem(400);
    .info-title {
      height: rem(130);
    }
    h1 {
      color: #222222;
      font-weight: bold;
      font-size: rem(26);
      margin-bottom: rem(10);
    }
    .sub {
      margin-bottom: rem(10);
      color: #989898;
      font-size: rem(24);
      @include ell;
    }
    .data {
      display: flex;
      align-items: center;
      > span {
        color: #989898;
        font-size: rem(22);
      }
      .price {
        flex: 1;
        i,
        b {
          color: #ff4848;
          font-weight: bold;
        }
        > i {
          font-size: rem(20);
        }
        > b {
          font-size: rem(30);
        }
        > span {
          font-size: rem(22);
          text-decoration-line: line-through;
          color: #c1c1c1;
        }
      }
    }
  }
}
</style>
