<template>
  <div class="index-search">
    <div class="search-input" @click="SearchShow = true">
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.04 11.5C22.04 17.2973 17.3317 22 11.52 22C5.70833 22 1 17.2973 1 11.5C1 5.70265 5.70833 1 11.52 1C17.3317 1 22.04 5.70265 22.04 11.5Z"
          stroke="#C1C1C1"
          stroke-width="2"
        />
        <path
          d="M19.8193 18.7751L24 22.9485"
          stroke="#C1C1C1"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
      <p>请输入商品名称</p>
    </div>
    <div class="search-msg search-block" @click="tipToast('暂无消息通知')">
      <svg
        width="40"
        height="34"
        viewBox="0 0 40 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="2"
          y="2"
          width="36"
          height="30"
          rx="6"
          stroke="#666666"
          stroke-width="3"
        />
        <path
          d="M11 9L20 18L29 9"
          stroke="#666666"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <!-- <i>12</i> -->
    </div>
    <!-- <div class="search-logo search-block">
      <svg
        width="26"
        height="35"
        viewBox="0 0 26 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.92315 7.81436V7.33269C6.92315 7.19479 6.81122 7.08231 6.67398 7.08231H4.49474C4.19448 7.08231 3.95018 6.83683 3.95018 6.53511V0.250372C3.95018 0.112472 3.83873 0 3.70101 0H3.22165C3.08442 0 2.97249 0.111983 2.97249 0.250372V6.5356C2.97249 6.83732 2.72819 7.0828 2.42792 7.0828H0.249167C0.111931 7.0828 0 7.19528 0 7.33318V7.81485C0 7.95275 0.111444 8.06522 0.249167 8.06522H2.42841C2.72867 8.06522 2.97297 8.3107 2.97297 8.61242V26.9982C2.97297 27.1361 3.08442 27.2485 3.22214 27.2485H3.70149C3.83873 27.2485 3.95066 27.1366 3.95066 26.9982V8.61193C3.95066 8.31021 4.19496 8.06473 4.49523 8.06473H6.67495C6.8117 8.06473 6.92315 7.95226 6.92315 7.81436Z"
          fill="#040000"
        />
        <path
          d="M16.9613 26.2578H16.9004C15.5208 26.2578 14.9324 25.5722 14.9324 23.9649V8.12244C14.9324 7.54932 14.4696 7.0828 13.9007 7.0828H10.8294C10.5292 7.0828 10.2849 6.83732 10.2849 6.5356V0.259665C10.2849 0.121765 10.1734 0.0092926 10.0357 0.0092926H9.55634C9.41911 0.0092926 9.30718 0.121765 9.30718 0.259665V6.5356C9.30718 6.83732 9.06288 7.0828 8.76261 7.0828H7.74502C7.62141 7.0828 7.52067 7.18403 7.52067 7.30824V7.8437C7.52067 7.96253 7.61703 8.05935 7.73528 8.05935H8.7631C9.06288 8.05935 9.30718 8.30435 9.30766 8.60558C9.30912 10.2418 9.31253 14.5549 9.31253 14.6639C9.04682 24.0377 8.68231 24.4984 7.14789 26.4392L7.12161 26.4725C7.05105 26.5615 7.0491 26.6872 7.11723 26.7781L7.3956 27.1512C7.43502 27.2045 7.49585 27.2383 7.56203 27.2446C7.56933 27.2451 7.57663 27.2456 7.58393 27.2456C7.64233 27.2456 7.69927 27.2236 7.74258 27.1835L7.76594 27.162C9.59041 25.2436 9.98655 24.4964 10.2596 14.6991C10.2596 14.6287 10.2669 12.5783 10.2815 8.60411C10.2824 8.30386 10.5267 8.05935 10.826 8.05935H13.4364C13.7387 8.05935 13.9844 8.3063 13.9844 8.60998V24.1771C13.9844 25.2089 14.2146 25.9571 14.6881 26.4652C15.1738 26.986 15.897 27.2393 16.8995 27.2393H16.9559C17.062 27.2393 17.1482 27.1527 17.1482 27.0461V26.4466C17.1491 26.3429 17.0649 26.2578 16.9613 26.2578Z"
          fill="#040000"
        />
        <path
          d="M25.7829 1.64064C25.9022 1.64064 25.9995 1.54284 25.9995 1.42303V0.906635C25.9995 0.786828 25.9022 0.689026 25.7829 0.689026H17.175C16.5993 0.689026 16.1306 1.15945 16.1306 1.73844V25.4397C16.1306 25.5595 16.2279 25.6573 16.3472 25.6573H16.8616C16.9808 25.6573 17.0781 25.5595 17.0781 25.4397V2.18784C17.0781 1.88612 17.3224 1.64064 17.6227 1.64064H25.7829Z"
          fill="#040000"
        />
        <path
          d="M17.9142 24.07C18.0013 24.0656 18.0461 24.0069 18.0977 23.934L18.1079 23.9189C20.0496 20.6865 20.0535 17.1745 20.0594 12.3025L20.0604 11.5518C20.0608 11.2531 20.3037 11.0095 20.601 11.0095H22.2576C22.5579 11.0095 22.8017 11.255 22.8017 11.5562V25.4064C22.8017 25.5424 22.9117 25.6524 23.0508 25.6524H23.5341C23.6694 25.6524 23.7789 25.5419 23.7789 25.402V11.5572C23.7789 11.2555 24.0232 11.01 24.3234 11.01H25.6043C25.7396 11.01 25.8496 10.8995 25.8496 10.7596V10.2741C25.8496 10.1381 25.7396 10.0281 25.6004 10.0281H20.6039C20.3061 10.0281 20.0633 9.78456 20.0633 9.4848V6.18693C20.0633 5.95611 20.2078 5.75171 20.4229 5.67787C20.8507 5.53166 21.7057 5.20989 22.8883 4.76489C23.4124 4.56782 24.0003 4.3463 24.6412 4.10619C24.7614 4.06121 24.8023 3.87538 24.7765 3.77269L24.6768 3.27243C24.6646 3.21229 24.6276 3.16094 24.5741 3.1316C24.521 3.10177 24.4578 3.09737 24.3994 3.11889L24.1444 3.2206C24.1079 3.23429 20.473 4.58493 19.8341 4.78836C19.4043 4.92528 19.1153 5.32383 19.1153 5.77958V10.7802C19.1167 16.3084 19.1172 20.3027 17.3492 23.3316C17.2918 23.4299 17.3078 23.5546 17.3876 23.6352L17.7088 23.958C17.775 24.024 17.8271 24.0739 17.9142 24.07Z"
          fill="#040000"
        />
        <path
          d="M25.7513 26.2642L17.9979 26.2632C17.9298 26.2578 17.8685 26.2891 17.8213 26.3361C17.7741 26.3835 17.7483 26.4461 17.7483 26.5131V26.9957C17.7483 27.1331 17.8597 27.2456 17.9965 27.2461H25.7499H25.7508C25.817 27.2461 25.8793 27.2202 25.9265 27.1732C25.9737 27.1258 26 27.0632 26 26.9962V26.5145C25.9995 26.3766 25.8881 26.2647 25.7513 26.2642Z"
          fill="#040000"
        />
        <path
          d="M2.42682 30.8041H1.20677C1.04618 30.8041 0.9104 30.9425 0.9104 31.1063V34.0311C0.9104 34.1949 1.04618 34.3333 1.20677 34.3333C1.37272 34.3333 1.50753 34.1978 1.50753 34.0311V33.0374H2.27255L3.15485 34.1895C3.24391 34.3089 3.34757 34.3338 3.41862 34.3338C3.57873 34.3338 3.71937 34.1949 3.71937 34.0365C3.71937 33.9607 3.69115 33.8937 3.6347 33.8301L2.93245 32.9215C3.43468 32.767 3.71937 32.4017 3.71937 31.9024V31.8931C3.71937 31.609 3.61815 31.3508 3.43419 31.166C3.20255 30.9327 2.84485 30.8041 2.42682 30.8041ZM3.11787 31.9068V31.9161C3.11787 32.2609 2.84047 32.4746 2.39421 32.4746H1.50753V31.3758H2.39908C2.85605 31.3758 3.11787 31.5694 3.11787 31.9068Z"
          fill="#040000"
        />
        <path
          d="M12.8863 30.7924C12.7227 30.7924 12.5899 30.9259 12.5899 31.0902V33.2277L10.8155 30.9538C10.7606 30.8829 10.6793 30.8022 10.5391 30.8022H10.488C10.3274 30.8022 10.1921 30.9386 10.1921 31.1V34.0482C10.1921 34.2096 10.3255 34.346 10.4836 34.346C10.6467 34.346 10.7795 34.2126 10.7795 34.0482V31.8457L12.5991 34.1837C12.6381 34.2292 12.7291 34.3363 12.877 34.3363H12.8955C13.0537 34.3363 13.1778 34.2096 13.1778 34.0482V31.0907C13.1778 30.9288 13.0444 30.7924 12.8863 30.7924Z"
          fill="#040000"
        />
        <path
          d="M15.5969 30.7924C15.4363 30.7924 15.3005 30.9308 15.3005 31.0946V34.0424C15.3005 34.2062 15.4363 34.3446 15.5969 34.3446C15.7629 34.3446 15.8977 34.2091 15.8977 34.0424V31.0946C15.8977 30.9283 15.7629 30.7924 15.5969 30.7924Z"
          fill="#040000"
        />
        <path
          d="M20.7144 30.7924C20.5509 30.7924 20.418 30.9259 20.418 31.0902V33.2277L18.6437 30.9538C18.5887 30.8829 18.5074 30.8022 18.3672 30.8022H18.3161C18.1556 30.8022 18.0203 30.9386 18.0203 31.1V34.0482C18.0203 34.2096 18.1536 34.346 18.3118 34.346C18.4753 34.346 18.6077 34.2126 18.6077 34.0482V31.8457L20.4273 34.1837C20.4662 34.2292 20.5572 34.3363 20.7051 34.3363H20.7236C20.8818 34.3363 21.0059 34.2096 21.0059 34.0482V31.0907C21.0059 30.9288 20.8725 30.7924 20.7144 30.7924Z"
          fill="#040000"
        />
        <path
          d="M25.4414 33.7543H23.6933V32.8379H25.2088C25.3645 32.8379 25.491 32.7108 25.491 32.5543C25.491 32.4008 25.3616 32.2707 25.2088 32.2707H23.6933V31.3826H25.418C25.5738 31.3826 25.7003 31.2555 25.7003 31.099C25.7003 30.9425 25.5738 30.8154 25.418 30.8154H23.3926C23.232 30.8154 23.0962 30.9538 23.0962 31.1176V34.0189C23.0962 34.1827 23.232 34.3211 23.3926 34.3211H25.4414C25.5971 34.3211 25.7236 34.1939 25.7236 34.0375C25.7236 33.882 25.5966 33.7543 25.4414 33.7543Z"
          fill="#040000"
        />
        <path
          d="M8.10471 33.7543H6.35665V32.8379H7.87209C8.02782 32.8379 8.15435 32.7108 8.15435 32.5543C8.15435 32.4008 8.0249 32.2707 7.87209 32.2707H6.35665V31.3826H8.08135C8.23708 31.3826 8.36361 31.2555 8.36361 31.099C8.36361 30.9425 8.23708 30.8154 8.08135 30.8154H6.0559C5.8953 30.8154 5.76001 30.9538 5.76001 31.1176V34.0189C5.76001 34.1827 5.89579 34.3211 6.0559 34.3211H8.10471C8.26044 34.3211 8.38697 34.1939 8.38697 34.0375C8.38697 33.882 8.26044 33.7543 8.10471 33.7543Z"
          fill="#040000"
        />
      </svg>
    </div> -->
    <oSearch :boo.sync="SearchShow"></oSearch>
  </div>
</template>

<script>
import { Toast } from "vant";
import oSearch from "@view/home/view/goodsSearch/setup.vue";
export default {
  components: {
    Toast,
    oSearch,
  },
  data() {
    return {
      SearchShow: false,
    };
  },
  methods: {
    tipToast(msg) {
      Toast(msg);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.index-search {
  display: flex;
  padding: rem(20) rem(30);
  .search-input {
    display: flex;
    width: rem(478);
    flex: 1;
    height: rem(66);
    border-radius: rem(66/2);
    background: rgba(246, 246, 246, 0.9);
    align-items: center;
    svg {
      width: rem(24);
      height: rem(23);
      margin-left: rem(24);
    }
    p {
      color: #c1c1c1;
      font-size: rem(24);
      margin-left: rem(10);
    }
  }
  .search-block {
    background: rgba(246, 246, 246, 0.9);
    height: rem(66);
    display: flex;
    border-radius: rem(66/2);
    width: rem(96);
    align-items: center;
    justify-content: center;
    margin-left: rem(10);
  }
  .search-msg {
    position: relative;

    svg {
      width: rem(36);
      height: rem(30);
    }
    i {
      font-size: rem(24);
      color: #ffffff;
      line-height: rem(36);
      position: absolute;
      width: rem(36);
      text-align: center;
      background: #ff4848;
      border-radius: 50%;
      height: rem(36);
      top: rem(-8);
      right: rem(-8);
    }
  }
  .search-logo {
    svg {
      width: rem(26);
      height: rem(34);
    }
  }
}
</style>
