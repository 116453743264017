var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"moZoomSwiper"},[_c('swiper',{ref:"mySwiper",staticClass:"swiper-container-left",staticStyle:{"z-index":"9999"},style:({
      height: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'bgheight')),
      background: 'url(' + _vm.getModelIdVal(_vm.moData, 'img') + ') no-repeat',
      backgroundSize: '100%',
      paddingTop: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'top')),
    }),attrs:{"options":Object.assign({}, _vm.leftTitle,
      {autoplay: {
        disableOnInteraction: false,
        delay: _vm.getModelIdVal(_vm.moData, 'speed') || 4000,
      }})}},_vm._l((_vm.moData.swiper),function(item,index){return (_vm.moData.swiper)?_c('swiper-slide',{key:index,style:({
        width: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'width')),
        height: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'height')),
      })},[_c('div',{staticStyle:{"width":"100%","height":"100%","position":"relative"},on:{"click":function($event){item.childimg ? _vm.zoomImg(item) : _vm.getModelPath(item)}}},[(item.childimg && item.childimg.length != 0)?_c('img',{staticClass:"zoomSiper_zoom",attrs:{"src":"http://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2021052814401953062.png"}}):_vm._e(),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.link),expression:"item.link"}],staticClass:"zoomSiper_img",attrs:{"src":item.link}})])]):_vm._e()}),1),(_vm.getModelIdVal(_vm.moData, 'istip') == 1)?[_c('img',{staticClass:"swiper-jt-next",style:({
        width: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'tipWidth')),
        top: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'tipTop')),
        right: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'tipLeft')),
      }),attrs:{"src":_vm.getModelIdVal(_vm.moData, 'tipImgRight')}}),_c('img',{staticClass:"swiper-jt-prev",style:({
        width: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'tipWidth')),
        top: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'tipTop')),
        left: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'tipLeft')),
      }),attrs:{"src":_vm.getModelIdVal(_vm.moData, 'tipImgLeft')}})]:_vm._e(),_c('van-popup',{style:({ width: '90%' }),attrs:{"get-container":"body","closeable":"","close-on-popstate":""},model:{value:(_vm.imgshow),callback:function ($$v) {_vm.imgshow=$$v},expression:"imgshow"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.zoomImgArr},on:{"click":function($event){return _vm.getModelPath(_vm.zoomImgHref)}}})])],2)}
var staticRenderFns = []

export { render, staticRenderFns }