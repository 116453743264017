<template>
  <div
    id="video"
    :class="getModelIdVal(moData, 'fixed', 'swiper') == 2 ? 'fixed_video' : ''"
  >
    <!-- poster=""  -->
    <!-- <div class="solid" v-if='play_video_icon'>
        <img src="http://image.shop.19jiang.cn/home/mofang/alioss_5fcaf1cbb76b8.png" alt="">
      </div> -->
    <video
      id="ckplayer_a1"
      class="video_player"
      :src="getSrc()"
      autoplay="autoplay"
      x5-video-player-type="h5"
      preload="metadata"
      loop
      :controls="getModelIdVal(moData, 'controls', 'swiper') == 2"
      playsinline="true"
      webkit-playsinline="true"
      x-webkit-airplay="true"
      x5-video-orientation="portraint"
      x5-video-player-fullscreen="true"
      :poster="
        (moData.contorl && moData.contorl[0] && moData.contorl[0].val) || 'auto'
      "
    ></video>
  </div>
</template>
<script>
import { setRem, getModelPath, getModelIdVal } from "../../js";
export default {
  name: "",
  props: {
    moData: {},
  },
  methods: {
    getModelIdVal,
    getSrc() {
      if (getModelIdVal(this.moData, "mode", "swiper") == 1) {
        return this.moData.video[0].link;
      } else {
        return this.moData.videoAds;
      }
    },
  },
  data: () => ({
    play_video_icon: true,
  }),
  created() {
    //do something after creating vue instance
  },
  mounted() {
    let that = this;
    function playVideo() {
      // var video = document.getElementById("ckplayer_a1");
      var allVideo = document.querySelectorAll(".video_player");
      if (allVideo && allVideo.length != 0) {
        for (let index = 0; index < allVideo.length; index++) {
          allVideo[index].volume =
            getModelIdVal(that.moData, "audio", "swiper") / 100;
          allVideo[index].play();
        }
      }

      document.removeEventListener("touchstart", playVideo);
      document.removeEventListener("WeixinJSBridgeReady", playVideo);
      // video.play();
      // this.play_video_ico = false;
    }

    this.$nextTick(() => {
      // playVideo();
      document.addEventListener("WeixinJSBridgeReady", playVideo, false);
      document.addEventListener("touchstart", playVideo, false);
    });
  },
};
</script>
<style lang="scss" scoped>
video {
  width: 100%;
}
#video {
  position: relative;
}
.solid {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  img {
    width: 3rem;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
.fixed_video {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 999999;
  left: 0;
}
</style>
