<template>
  <!-- <div class="div" :style="{ background: color }"></div> -->
  <div class="index-swiper" :style="{ background: color }">
    <oSearch></oSearch>
    <template v-if="image_list && image_list.length != 0">
      <img
        class="img1"
        :src="image_list[0].image_url"
        v-if="image_list.length == 1"
        @click="goHref(image_list[0].location_url)"
      />
      <swiper
        v-else
        @slideChange="onSlideChange"
        ref="mySwiper"
        :options="{
          ...leftTitle,
          autoplay: {
            disableOnInteraction: false,
            delay: 4000,
          },
        }"
        class="swiper-container-left"
        style="z-index: 99;"
      >
        <swiper-slide
          v-for="(item, index) in image_list"
          style="width:90%"
          :key="index"
        >
          <img
            @click="goHref(item.location_url)"
            class="zoomSiper_img"
            :src="item.image_url"
          />
        </swiper-slide>

        <!-- <div class="swiper-pagination" slot="pagination"></div> -->
      </swiper>
    </template>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import { get } from "@get/http";
import oSearch from "./search";
import "swiper/swiper.scss";
export default {
  components: {
    Swiper,
    SwiperSlide,
    oSearch,
  },
  data() {
    return {
      leftTitle: {
        loop: true,
        speed: 500,
        autoplay: true,
        // slidesPerView: 3,
        slidesPerView: "auto",
        centeredSlides: true,
      },
      color: "",
      image_list: [],
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  methods: {
    goHref(src) {
      if (src) {
        window.location.href = src;
      }
    },

    onSlideChange() {
      let swiper = this.swiper;
      this.color = this.image_list[swiper.realIndex].color;
      // if (swiper.realIndex == 0) {
      //   this.color = "#f79cac";
      // } else if (swiper.realIndex == 1) {
      //   this.color = "#E1D1A6";
      // } else if (swiper.realIndex == 2) {
      //   this.color = "#D2D0CE";
      // }
    },
  },
  async created() {
    let { image_list } = await get("/GoodsArticle/getImage", {
      params: {
        type: 3,
      },
    });
    this.image_list = image_list;
    this.color = this.image_list[0].color;
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.index-swiper {
  background: #fff;
  box-sizing: border-box;
  padding-bottom: rem(20+16);
  transition-timing-function: linear;
  transition: 0.8s;
}

.swiper-container {
  margin: 0;
  width: 100%;
  height: 100%;
}

.swiper-container2 .swiper-slide {
  height: auto;
}

// 左边
.img1 {
  width: rem(750);
  height: rem(321);
  transform: scale(0.94);
  border-radius: rem(16);
}
.swiper-container-left {
  position: relative;
  // background: #fff;
  // overflow: hidden;
  width: rem(750);
  height: rem(321);
  .swiper-slide {
    height: auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: rem(321);
    margin: 0 auto;

    .zoomSiper_img {
      display: block;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      transition: 300ms;
      transform: scale(0.94);
      border-radius: rem(16);
    }
  }

  .swiper-slide-active {
    // transform: scale(1);
    // margin: 0 rem(20);
    .zoomSiper_img {
      transform: scale(1);
    }
    .zoomSiper_zoom {
      top: rem(10);
      right: rem(0);
      width: rem(40);
    }
  }
}
</style>
