<!-- 弹窗 -->
<!-- 目前在使用的路径   /home/index -->
<!-- 弹窗 -->
<!-- 目前在使用的路径   /home/index -->

<template>
  <div>
    <template v-for="item in list">
      <o-pos :list="item" v-if="item.image_type == 2"></o-pos>
      <!-- 悬浮 -->
      <o-popup :list="item" v-if="item.image_type == 1"></o-popup>
      <!-- 弹窗 -->
      <oKefus :list="item" v-if="item.image_type == 3"></oKefus>
      <!-- 客服 -->
    </template>
  </div>
</template>

<script>
import oPos from "./components/pos";
import oPopup from "./components/popup";
import oKefus from "./components/kefu";
import { post } from "@get/http";
export default {
  name: "",
  data: () => ({
    list: [],
  }),
  components: {
    oPos,
    oPopup,
    oKefus,
  },
  mounted() {
    this.getPopup();
  },
  methods: {
    async getPopup() {
      if (this.$route.query?.ads == "none") {
        return false;
      }
      let list = await post("/index/layer_v2", {
        data: {
          type: 1,
          store_id: process.env.VUE_APP_STORE,
          key: this.$store.state.user.token,
        },
      });
      this.list = list;
    },
  },
};
</script>
<style lang="scss" scoped></style>
