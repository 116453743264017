<template>
  <div>
    <oTitle title="热门专栏" :ismore="false" class="all-title"></oTitle>

    <div class="tab-kkz">
      <van-tabs
        id="tab-study-course"
        line-width="0.48rem"
        background="#F8F8F8"
        @change="changeActive"
        v-model="active"
        v-if="typeList && typeList.length != 0"
      >
        <van-tab
          :title="item.alias || item.value"
          title-class="course-title"
          v-for="item in typeList"
          :key="item.key"
        >
          <oBlock :list="courseList"></oBlock>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { Tab, Tabs } from "vant";
import oTitle from "../../view/index/components/title";
import oBlock from "./block";
import { post } from "@get/http";
import { getClassList } from "../../view/index/components/study/js/index.js";
import { setRem, getModelPath, getModelIdVal } from "../../js";
export default {
  components: {
    vanTab: Tab,
    vanTabs: Tabs,
    oBlock,
    oTitle,
  },
  props: {
    moData: {},
  },
  data() {
    return {
      active: 0,
      typeList: [],
      courseList: [],
    };
  },

  async created() {
    if (this.moData?.tab?.content?.length != 0) {
      this.typeList = this.moData.tab.content;
      this.active = Number(this.moData?.tab?.current) || 0;
      this.getCourseList();
    }
  },
  methods: {
    changeActive(data) {
      this.getCourseList();
    },
    getModelIdVal,
    async getCourseList() {
      let data = await getClassList({
        type: this.typeList[this.active].key,
        pageSize: this.getModelIdVal(this.moData, "number"),
      });

      this.courseList = data.list;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

.all-title {
  padding: rem(30) rem(30) 0;
  margin-bottom: rem(30);
}
.tab-kkz {
  background: #fff;
  width: rem(690);
  margin: 0 auto;
  border-radius: rem(16);
}
#tab-study-course {
  // margin-bottom: rem(32);
  ::v-deep {
    .van-tabs__wrap {
      display: block;
    }
    .van-tabs__nav {
      background: none !important;
    }

    .van-tab {
      padding: 0 rem(20);
      // width: rem(104);

      &:first-of-type {
        padding-left: rem(30);
      }
      flex: none;
      span {
        color: #666;
        font-size: rem(30);
      }
    }
    .van-tabs__nav--line {
      padding-bottom: rem(18);
    }
    .van-tab--active {
      span {
        color: #ff4848;
        font-weight: bold;
      }
    }
  }
}
</style>
