<template>
  <div
    :style="{
      padding: setRem(getModelIdVal(moData, 'inside')) + ' 0',
      background: getModelIdVal(moData, 'bgcolor'),
    }"
  >
    <pre
      v-html="getModelIdVal(moData, 'text')"
      class="text-content"
      :style="{
        color: getModelIdVal(moData, 'color'),
        fontSize: setRem(getModelIdVal(moData, 'size') * 2),
        textAlign: textAlign(getModelIdVal(moData, 'pos')),
      }"
    ></pre>

    <div
      v-if="getModelIdVal(moData, 'copy') == 1"
      class="place_text_CopyText"
      @click="doCopy(getModelIdVal(moData, 'text'))"
    >
      复制文本
    </div>
  </div>
</template>

<script>
import { setRem, getModelPath, getModelIdVal } from "../../js";
import { Toast } from "vant";
export default {
  name: "",
  data: () => ({
    //Image list
    someList: [],
  }),
  components: {},
  props: {
    moData: {},
  },

  created() {
    //do something after creating vue instance
    // console.log(this.moData);
  },
  methods: {
    getModelPath(data) {
      getModelPath(data, this);
    },
    getModelIdVal,
    setRem,
    textAlign(val) {
      if (val == 2) {
        return "left";
      } else if (val == 1) {
        return "center";
      } else if (val == 3) {
        return "right";
      } else {
        return "center";
      }
    },
    doCopy: function(copy) {
      this.$copyText(copy).then(
        function(e) {
          Toast("微信:已复制");
        },
        function(e) {
          Toast("微信:复制失败");
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";
.wrap1 {
  // height: 30px;
  @include ell;
  padding: 0 rem(26);
}
.wrap2 {
  // height: 60px;
  @include ell2;
  word-break: break-all;
  padding: 0 rem(26);
}

.flex-container {
  align-items: center;
}
.text-content {
  padding: 0 rem(10);
  box-sizing: border-box;
  display: block;
}
.place_text_CopyText {
  // 复制文本按钮
  background: #00c000;
  text-align: center;
  color: #fff;
  margin: rem(20) auto 0;
  width: rem(240);
  height: rem(80);
  line-height: rem(80);
  border-radius: rem(24);
  font-size: rem(28);
}
</style>
