<template>
  <div class="new-index">
    <oSwiper></oSwiper>
    <oTab></oTab>
  </div>
</template>

<script>
import oSwiper from "./components/swiper";
import oTab from "./components/tab";
export default {
  components: {
    oSwiper,
    oTab,
  },
  created() {},
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

.new-index {
  width: rem(750);
  margin: 0 auto;
}
</style>
