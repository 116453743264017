<template>
  <div id="">
    <img
      :src="imgLocation(list.image)"
      class="pos"
      @click="goKefu"
      :style="{
        bottom: size.bottom,
        right: size.right,
        width: size.width,
        height: size.height,
      }"
    />

    <oChat :boo.sync="kefuBoo" class="rem100"></oChat>
  </div>
</template>
<script>
import { sizeChange, imgLocation } from "./index.js";
// import oChat from "@/components/kefu/";
import oChat from "@view/me/view/chat/popup.vue";
export default {
  props: ["list"],
  data: () => ({
    kefuBoo: false,
    fontSize: "rem",
    size: {
      bottom: "10%",
      right: "5%",
      width: "20%",
      height: "auto",
    },
    type: "common",
  }),
  components: {
    oChat,
  },

  created() {
    if (this.list.image_config == null || this.list.image_config.length == 0) {
      // 预留
      return false;
    } else {
      for (let val in this.list.image_config) {
        if (this.list.image_config[val] != 0) {
          this.size[val] = this.sizeChange(
            this.list.image_config[val],
            this.fontSize
          );
        }
      }
    }
  },
  methods: {
    sizeChange,
    imgLocation,

    goKefu() {
      if (window.__wxjs_environment === "miniprogram") {
        wx.miniProgram.navigateTo({
          url: "../chat/index",
        });
        return false;
      } else {
        this.kefuBoo = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";
.pos {
  position: fixed;
  z-index: 999;
  bottom: 10%;
  right: 5%;
  width: 20%;
  height: auto;
}
.rem100 {
  // margin-bottom: rem(90);
}
</style>
<style lang="scss">
.van-share-sheet__options {
  flex-wrap: wrap;
}
.van-share-sheet__option {
  margin-bottom: 10px;
}
</style>
