<template>
  <div>
    <van-popup
      get-container="#app"
      v-model="SearchShow"
      v-if="endPage"
      position="right"
      lock-scroll
      close-on-popstate
      :overlay="false"
      :style="{ height: '100%', width: '100%', background: '#f8f8f8' }"
    >
      <oSearchResult
        :boo.sync="SearchShow"
        :endPage.sync="endPage"
      ></oSearchResult>
    </van-popup>
  </div>
</template>

<script>
import { Popup } from "vant";
import oSearchResult from "./";
import { getDevice } from "@get/util";
export default {
  props: {
    boo: {
      type: Boolean,
      default: false,
    },
  },
  methods: {},
  created() {
    this.SearchShow = this.boo;
  },
  watch: {
    boo() {
      this.SearchShow = this.boo;
      if (this.boo) {
      }
    },
    SearchShow() {
      this.$emit("update:boo", this.SearchShow);
    },
    endPage() {
      if (this.endPage == false) {
        clearTimeout(this.timer2);
        this.timer2 = setTimeout(() => {
          this.endPage = true;
          document.title = this.$route.meta.title;
        }, 500);
      } else {
        if (getDevice().isAndroid) {
          clearTimeout(this.timer1);
          setTimeout(() => {
            document.querySelector(".van-field__control").focus();
          }, 500);
        }
      }
    },
  },
  data() {
    return {
      endPage: true,
      AndroidBoo: true,
      timer1: false,
      timer2: false,
      SearchShow: false,
    };
  },
  components: {
    vanPopup: Popup,
    oSearchResult,
  },
};
</script>

<style lang="scss" scoped></style>
