<template>
  <div>
    <div
      class="bm-i_list"
      @click="
        $router.push({ name: 'bbsMall_Detail', query: { a_id: item.id } })
      "
    >
      <div class="bm-i-l_top">
        <template v-if="item.image_video_type == 1">
          <img
            class="b-i-l-img"
            v-if="
              item.image_list &&
                item.image_list[0] &&
                item.image_list[0].image_url
            "
            v-lazy="
              item.image_list &&
                item.image_list[0] &&
                item.image_list[0].image_url
            "
          />
        </template>
        <template v-else>
          <img
            class="b-i-l-img"
            v-if="item.video_index_image && item.video_index_image.image_url"
            :src="item.video_index_image && item.video_index_image.image_url"
          />
        </template>

        <img
          class="playicon"
          src="@image/bbsMall/kk_icon/play_icon.png"
          v-if="item.image_video_type == 2"
        />
      </div>
      <div class="bm-i-l_bottom">
        <h2>
          <!-- <img src="@image/bbsMall/kk_icon/Hot_icon.png" /> -->
          {{ item.title }}
        </h2>
      </div>
      <div class="bm-i-l_control flex-container">
        <div class="headImg flex-container">
          <img :src="item.author && item.author.author_avatar" />
          <span>{{ item.author && item.author.author_name }}</span>
        </div>
        <!-- <div class="control flex1 flex-container">
            <span>8888</span>
          </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {},
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.b-i-l-img {
  width: rem(335);
}
.bm-i-img_251 {
  width: rem(335);
  height: rem(251);
}
.bm-i-img_447 {
  width: rem(335);
  height: rem(447);
}
.bm-i-img_335 {
  width: rem(335);
  height: rem(335);
}

.bm-i_list {
  width: rem(335);
  display: inline-block;
  background: #fff;
  border-radius: rem(8);
  margin-bottom: rem(20);
}

.bm-i-l_top {
  border-radius: rem(8) rem(8) 0 0;
  position: relative;
  margin-bottom: rem(20);
  img {
    border-radius: rem(8) rem(8) 0 0;
  }
  .playicon {
    position: absolute;
    width: rem(30);
    height: rem(30);
    top: rem(10);
    right: rem(10);
    z-index: 2;
  }
}

.bm-i-l_bottom {
  margin-bottom: rem(20);
  box-sizing: border-box;
  padding: 0 rem(20);
  h2 {
    font-size: rem(26);
    line-height: rem(36);
    font-weight: bold;
    text-align: justify;
    color: #333333;
    @include ell2;
    img {
      display: inline-block;
      width: rem(17);
      margin-bottom: rem(8);
      height: rem(21);
    }
  }
}

.bm-i-l_control {
  margin-bottom: rem(20);
  box-sizing: border-box;
  padding: 0 rem(20);
  .headImg {
    img {
      width: rem(34);
      height: rem(34);
      border-radius: 50%;
      margin-right: rem(10);
    }
    span {
      @include ell;
      font-size: rem(24);
      line-height: rem(34);
      text-align: justify;
      color: #666666;
      width: 74px;
    }
  }
  .control {
    flex-direction: row-reverse;

    span {
      padding-left: rem(34);
      background: url("~@image/bbsMall/kk_icon/dianzan_icon.png") no-repeat 0
        rem(3);
      background-size: rem(24);
      color: #666666;
      font-size: rem(24);
      line-height: rem(34);
    }
  }
}
</style>
