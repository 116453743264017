<template>
  <div class="components_infoList">
    <h1 class="bm_h1" v-if="title">{{ title }}</h1>

    <div class="bm_info">
      <oList :item="item" v-for="item in list" :key="item.id"></oList>
    </div>

    <div class="bm_dx flex-container" v-if="finished">
      <i></i>
      <span>我是有底线的</span>
      <i></i>
    </div>
  </div>
</template>

<script>
import oList from "./item";
export default {
  data() {
    return {};
  },
  components: {
    oList,
  },
  props: {
    finished: {
      default: false,
      type: Boolean,
    },
    list: {},
    title: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

.bm_dx {
  justify-content: center;
  align-items: center;
  margin-top: rem(30);
  padding-bottom: rem(50);
  i {
    width: rem(160);
    height: rem(2);
    background: #e9e8e8;
  }
  span {
    font-size: rem(26);
    line-height: rem(36);
    text-align: justify;
    color: #cccccc;
    margin: 0 rem(20);
    letter-spacing: rem(4);
  }
}

.bm_h1 {
  font-size: rem(38);
  line-height: rem(54);
  font-weight: bold;
  color: #333333;
  margin: rem(30);
}

.bm_info {
  column-count: 2;
}

.bm_info {
  // column-gap: 30px;

  margin: 0 rem(30);
  box-sizing: border-box;
}
</style>
