<template>
  <div id="">
    <img
      :src="imgLocation(list.image)"
      class="pos"
      @click="toHref(list.link)"
      :style="{
        bottom: size.bottom,
        right: size.right,
        width: size.width,
        height: size.height,
      }"
    />
  </div>
</template>
<script>
import { toHref, sizeChange, imgLocation } from "./index.js";
export default {
  props: ["list"],
  data: () => ({
    fontSize: "rem",
    size: {
      bottom: "10%",
      right: "5%",
      width: "20%",
      height: "auto",
    },
  }),
  created() {
    if (this.list.image_config == null || this.list.image_config.length == 0) {
      // 预留
      return false;
    } else {
      for (let val in this.list.image_config) {
        if (this.list.image_config[val] != 0) {
          this.size[val] = this.sizeChange(
            this.list.image_config[val],
            this.fontSize
          );
        }
      }
    }
  },
  methods: {
    toHref,
    sizeChange,
    imgLocation,
  },
};
</script>
<style lang="scss" scoped>
.pos {
  position: fixed;
  z-index: 999;
  bottom: 10%;
  right: 5%;
  width: 20%;
  height: auto;
}
</style>
