var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":""}},[_c('div',{style:({
      background: _vm.getModelIdVal(_vm.moData, 'bgcolor'),
      'padding-top': _vm.setRem(_vm.getModelIdVal(_vm.moData, 'top')),
      'padding-bottom': _vm.setRem(_vm.getModelIdVal(_vm.moData, 'bottom')),
    })},[_vm._l((Number(_vm.getModelIdVal(_vm.moData, 'row'))),function(items,i){return [_c('div',{staticClass:"icon-box flex-container"},_vm._l((Number(_vm.getModelIdVal(_vm.moData, 'col'))),function(cols,ii){return _c('div',{key:ii,staticClass:"icon-box-li",class:_vm.getModelIdVal(_vm.moData, 'pos') == 1
              ? 'flex1'
              : 'fix' + _vm.getModelIdVal(_vm.moData, 'pos'),on:{"click":function($event){return _vm.getModelPath(_vm.moData.swiper[i * 5 + ii])}}},[_c('img',{style:({
              opacity:
                _vm.moData.swiper &&
                _vm.moData.swiper[i * 5 + ii] &&
                _vm.moData.swiper[i * 5 + ii].link
                  ? 1
                  : 0,
            }),attrs:{"src":_vm.moData.swiper &&
                _vm.moData.swiper[i * 5 + ii] &&
                _vm.moData.swiper[i * 5 + ii].link}}),_c('p',{style:({
              'font-size': _vm.setRem(_vm.getModelIdVal(_vm.moData, 'size') * 2),
              color: _vm.getModelIdVal(_vm.moData, 'color'),
            })},[_vm._v(" "+_vm._s(_vm.moData.swiper && _vm.moData.swiper[i * 5 + ii] && _vm.moData.swiper[i * 5 + ii].title)+" ")])])}),0)]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }