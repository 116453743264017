<template>
  <div class="study-offline" v-if="classList && classList.length != 0">
    <oTitle
      title="线下爆品课"
      class="class-title"
      @go="goRouter('Home_offloneList')"
      :ismore="false"
    ></oTitle>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div class="offline-ul">
        <div
          class="offline-li"
          v-for="item in classList"
          :key="item.id"
          v-if="item.status == 1"
        >
          <img class="mainImg" :src="item.cover_img" />
          <div class="info">
            <div class="info-left">
              <div class="info-title">
                <i>正式课</i>
                <h1>{{ item.title }}</h1>
              </div>
              <p class="info-quota">报名数：{{ item.open }}</p>
              <div class="info-add">
                <svg
                  class="info-add-icon"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26 12.0769C26 18.1945 15 28 15 28C15 28 4 18.1945 4 12.0769C4 5.95931 8.92487 1 15 1C21.0751 1 26 5.95931 26 12.0769Z"
                    stroke="#989898"
                    stroke-width="2"
                  />
                  <circle
                    cx="15"
                    cy="12"
                    r="3"
                    stroke="#989898"
                    stroke-width="2"
                  />
                </svg>
                <p class="info-add-text">
                  {{ item.subtitle }}
                </p>
              </div>
            </div>
            <div class="info-right">
              <div class="info-submit" @click="goHref(item.link)">
                报名
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import oTitle from "../../title.vue";
import { getClassList } from "../js/index.js";
import { List } from "vant";
export default {
  components: {
    oTitle,
    vanList: List,
  },
  data() {
    return {
      classList: [],
      loading: false,
      finished: false,
      page: 1,
      pageSize: 10,
    };
  },
  methods: {
    async onLoad() {
      // 异步更新数据

      let data = await getClassList({
        page: this.page,
        pageSize: this.pageSize,
        type: 8,
      });

      if (data.list && data.list.length != 0) {
        this.classList.push(...data.list);
        // 加载状态结束
        this.page++;
        this.loading = false;
      }

      // 数据全部加载完成
      if (data.list.length < 10) {
        this.finished = true;
      }
    },
    goHref(src) {
      if (src) {
        window.location.href = src;
      }
    },
    goRouter(name) {
      this.$router.push({
        name,
      });
    },
  },
  async created() {
    this.onLoad();
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.class-title {
  padding: rem(32) 0 rem(24);
}
.study-offline {
  width: rem(690);
  margin: 0 auto;
  .offline-ul {
  }
}

.offline-li {
  border-radius: rem(16);
  background: #fff;
  margin-bottom: rem(24);
  .mainImg {
    display: block;
    border-radius: rem(16) rem(16) 0 0;
    width: rem(690);
    height: rem(290);
  }
  .info {
    padding: rem(34) rem(24);
    box-sizing: border-box;
    display: flex;
    .info-right {
      .info-submit {
        width: rem(132);
        height: rem(64);
        border-radius: rem(64/2);
        background: #ff4848;
        line-height: rem(64);
        text-align: center;
        color: #fff;
      }
    }
    .info-left {
      width: 80%;
      .info-title {
        display: flex;
        align-items: center;
        margin-bottom: rem(12);
        > i {
          background: #fb7e1c;
          border-radius: rem(8) 0px;
          padding: 0 rem(10);
          height: rem(32);
          line-height: rem(32);
          color: #fff;
        }
        > h1 {
          font-weight: bold;
          font-size: rem(26);
          color: #222222;
          margin-left: rem(20);
          @include ell;
        }
      }
      .info-quota {
        color: #989898;
        font-size: rem(24);
        margin-bottom: rem(20);
      }
      .info-add {
        display: flex;
        align-items: center;
        .info-add-icon {
          display: block;
          width: rem(30);
          height: rem(30);
        }
        .info-add-text {
          flex: 1;
          margin-left: rem(14);
          color: #989898;
          font-size: rem(24);
        }
      }
    }
  }
}
</style>
