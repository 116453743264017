var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.src && _vm.getModelIdVal(_vm.moData, 'time'))?_c('div',{staticClass:"countdown-time",on:{"click":function($event){_vm.go(_vm.getModelIdVal(_vm.moData, 'text'))}}},[_c('img',{staticClass:"mo_image",attrs:{"src":_vm.src}}),(_vm.changeBoo)?_c('div',{staticClass:"ct-wrap",style:({
      top: _vm.getModelIdVal(_vm.moData, 'top') + '%',
      left: _vm.getModelIdVal(_vm.moData, 'left') + '%',
    })},[_c('van-count-down',{style:({
        fontSize: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'size') * 2),
      }),attrs:{"time":Number(_vm.getModelIdVal(_vm.moData, 'time')) - Number(_vm.nowTime)},on:{"finish":_vm.finish},scopedSlots:_vm._u([{key:"default",fn:function(timeData){return [(_vm.getModelIdVal(_vm.moData, 'mode') == 1)?_c('span',{style:({
            color: _vm.getModelIdVal(_vm.moData, 'color'),
            width: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'width') * 2),
            lineHeight: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'height') * 2),
            borderRadius: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'radius') * 2),
            height: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'height') * 2),
            background: _vm.getModelIdVal(_vm.moData, 'bgcolor'),
          })},[_vm._v(_vm._s(timeData.days))]):_vm._e(),(
            _vm.getModelIdVal(_vm.moData, 'mode') == 1 &&
              _vm.getModelIdVal(_vm.moData, 'show') != 3
          )?_c('b',{style:({
            color: _vm.getModelIdVal(_vm.moData, 'descolor'),
            fontSize: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'dessize') * 2),
          })},[_vm._v(_vm._s(_vm.getModelIdVal(_vm.moData, "show") == "2" ? " : " : "天"))]):_vm._e(),_c('span',{style:({
            color: _vm.getModelIdVal(_vm.moData, 'color'),
            width: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'width') * 2),
            lineHeight: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'height') * 2),
            borderRadius: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'radius') * 2),
            background: _vm.getModelIdVal(_vm.moData, 'bgcolor'),
            height: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'height') * 2),
          })},[_vm._v(_vm._s(timeData.hours))]),(_vm.getModelIdVal(_vm.moData, 'show') != 3)?_c('b',{style:({
            color: _vm.getModelIdVal(_vm.moData, 'descolor'),
            fontSize: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'dessize') * 2),
          })},[_vm._v(_vm._s(_vm.getModelIdVal(_vm.moData, "show") == "2" ? " : " : "时"))]):_vm._e(),_c('span',{style:({
            color: _vm.getModelIdVal(_vm.moData, 'color'),
            width: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'width') * 2),
            lineHeight: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'height') * 2),
            borderRadius: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'radius') * 2),
            background: _vm.getModelIdVal(_vm.moData, 'bgcolor'),
            height: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'height') * 2),
          })},[_vm._v(_vm._s(timeData.minutes))]),(_vm.getModelIdVal(_vm.moData, 'show') != 3)?_c('b',{style:({
            color: _vm.getModelIdVal(_vm.moData, 'descolor'),
            fontSize: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'dessize') * 2),
          })},[_vm._v(_vm._s(_vm.getModelIdVal(_vm.moData, "show") == "2" ? " : " : "分"))]):_vm._e(),_c('span',{style:({
            color: _vm.getModelIdVal(_vm.moData, 'color'),
            width: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'width') * 2),
            height: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'height') * 2),
            borderRadius: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'radius') * 2),
            background: _vm.getModelIdVal(_vm.moData, 'bgcolor'),
            lineHeight: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'height') * 2),
          })},[_vm._v(_vm._s(timeData.seconds))]),(_vm.getModelIdVal(_vm.moData, 'show') != 3)?_c('b',{style:({
            color: _vm.getModelIdVal(_vm.moData, 'descolor'),
            fontSize: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'dessize') * 2),
          })},[_vm._v(_vm._s(_vm.getModelIdVal(_vm.moData, "show") == "2" ? "" : "秒")+" ")]):_vm._e()]}}],null,false,661229750)})],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }