<template>
  <!-- 直播模块 -->

  <div class="place_xiaoet-live mo-place">
    <div class="x_l-title">
      直播课堂
    </div>

    <div
      class="x_l-content"
      v-for="(sw, sw_index) in moData.swiper"
      :key="sw_index"
      @click="getModelPath(sw)"
    >
      <img class="mainImg" :src="sw.link" />
      <div class="text">
        <p class="h1">
          {{ sw.title }}
        </p>
      </div>

      <div class="book">
        <div class="booked">
          {{ sw.count && sw.count.length != 0 ? `${sw.count} 已预约` : "" }}
        </div>
        <div class="btn">立即预约</div>
      </div>
    </div>
  </div>
</template>
<script>
import { setRem, getModelPath, getModelIdVal } from "../../js";
export default {
  name: "",
  data: () => ({}),
  created() {
    //do something after creating vue instance
    // console.log(this.moData);
  },
  props: {
    moData: {},
  },
  methods: {
    getModelPath(data) {
      getModelPath(data, this);
    },
    getModelIdVal,
    setRem,
  },
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";
// .mo_image {
//   width: 100%;
// }

.place_xiaoet-live {
  box-sizing: border-box;
  padding: 0 rem(24);
  width: rem(750);
  margin: 0 auto;
  .x_l-title {
    font-weight: 600;
    font-size: rem(30);
    color: #222222;
    margin: rem(24) 0;
  }
  .x_l-content {
    width: rem(702);
    // height: 539/2 ;
    background: #ffffff;
    border-radius: rem(16);
    padding: rem(24);
    box-sizing: border-box;
    margin-bottom: rem(24);
    &:last-of-type {
      margin-bottom: 0;
    }
    .mainImg {
      width: rem(654);
      height: rem(327);
      border-radius: rem(8);
      margin-bottom: rem(16);
    }
    .text {
      .h1 {
        font-weight: 600;
        font-size: rem(28);
        line-height: rem(39);
        text-align: justify;
        color: #222222;
        margin-bottom: rem(20);
      }
    }
    .book {
      display: flex;
      align-items: center;
      .peopel {
        display: flex;
        margin-right: rem(16);
        img {
          width: rem(44);
          height: rem(44);
          border: rem(2) solid #ffffff;
          border-radius: 50%;
          &:nth-of-type(1n + 1) {
            margin-left: rem(-8);
          }
        }
      }
      .booked {
        font-weight: 500;
        font-size: rem(24);
        line-height: rem(34);
        color: #999999;
        flex: 1;
        color: #999999;
      }

      .btn {
        width: rem(176);
        height: rem(54);
        background: #ff4848;
        border-radius: rem(8);
        font-weight: 600;
        font-size: rem(24);
        line-height: rem(34);
        color: #ffffff;
        line-height: rem(54);
        text-align: center;
      }
    }
  }
}
</style>
