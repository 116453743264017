<template>
  <div>
    <div>
      <van-sticky
        :class="{
          cardLift: getModelIdVal(moData, 'fixed') == 1 ? 'cardLift' : '',
        }"
      >
        <div class="lift-text_card" ref="lift">
          <template v-if="getModelIdVal(moData, 'mode') == 1">
            <!-- 图片 -->
            <img
              :src="
                active != index
                  ? moData.tabsImgs[index].default
                  : moData.tabsImgs[index].click
              "
              style="width: 100%; vertical-align: top; height: 100px"
              v-for="(item, index) in getModelIdVal(moData, 'col')"
              :key="index"
              @click.prevent="
                active = index;
                scrollBoo = false;
                skipModel(moData.tabsImgs[index].name);
              "
              :style="{
                width: 100 / getModelIdVal(moData, 'col') + '%',
                height: setRem(getModelIdVal(moData, 'height') || 100),
              }"
            />
          </template>
          <template v-else>
            <!-- 文本 -->
            <!-- :href="'#' + moData.tabsTexts[index].name" -->
            <!-- @click.prevent="skipModel(moData.tabsTexts[index].name)" -->
            <a
              @click.prevent="
                active = index;
                scrollBoo = false;
                skipModel(moData.tabsTexts[index].name);
              "
              v-for="(item, index) in getModelIdVal(moData, 'col')"
              :key="index"
              class="lift-text_box"
              :style="{
                width: 100 / getModelIdVal(moData, 'col') + '%',
                height: setRem(getModelIdVal(moData, 'height') || 100),
                background:
                  active != index
                    ? moData.tabsPublicSet.bg
                    : moData.tabsTexts[index].bg,
                color: moData.tabsPublicSet.color,
                fontSize: setRem(moData.tabsPublicSet.size * 2),
              }"
            >
              <!-- background: active != index ? '' : moData.tabsTexts[index].bg, -->
              <span
                :style="{
                  color: active != index ? '' : moData.tabsTexts[index].color,
                }"
                >{{ moData.tabsTexts[index].text }}</span
              >
            </a>
          </template>
        </div>
      </van-sticky>
    </div>
  </div>
</template>

<script>
import { Sticky } from "vant";
import { setRem, getModelPath, getModelIdVal } from "../../js";
export default {
  components: {
    vanSticky: Sticky,
  },
  props: {
    moData: {},
  },
  data() {
    return {
      active: -1,
      height: 0,
      scrollArr: [],
      scrollHeight: [],
      lift_height: "",
      scrollBoo: true,
    };
  },
  watch: {},
  mounted() {
    this.lift_height = this.$refs.lift.offsetHeight;
    let that = this;
    this.$nextTick(() => {
      let mode =
        that.getModelIdVal(that.moData, "mode") == 1 ? "tabsImgs" : "tabsTexts";
      for (
        let index = 0;
        index < that.getModelIdVal(that.moData, "col");
        index++
      ) {
        if (
          that.moData[`${mode}`][index]?.name == "" ||
          that.moData[`${mode}`][index]?.name == undefined ||
          that.moData[`${mode}`][index]?.name.length == 0
        ) {
          return false;
        }
      }
      that.searchDom(mode);
    });
  },
  deactivated() {
    window.removeEventListener("scroll", this.scrollMove);
  },
  methods: {
    scrollMove() {
      if (!this.scrollBoo) {
        return false;
      }
      let top =
        (document.body.scrollTop || document.documentElement.scrollTop) + 5;
      for (let index = 0; index < arr.length; index++) {
        if (
          that.scrollArr[index] - that.lift_height <= top &&
          that.scrollArr[index] + that.scrollHeight[index] - that.lift_height >=
            top
        ) {
          if (that.active !== index) {
            that.active = index;
            break;
            return false;
          }
        } else {
          if (that.scrollArr[0] > top + that.lift_height) {
            that.active = -1;
            break;
            return false;
          }
        }
      }
    },
    async searchDom(mode) {
      let that = this;
      let time = 500;
      let chearTime = null;
      let arr = [];
      let height = [];
      clearTimeout(chearTime);
      chearTime = setTimeout(() => {
        //  做定时兼容处理  因为DOM未加载完就执行完了  高度不是真实的高度
        clearTimeout(chearTime);
        for (
          let index = 0;
          index < Number(that.getModelIdVal(that.moData, "col"));
          index++
        ) {
          let d = that.moData[`${mode}`][index];
          let dn = d?.name?.trim();
          if (dn) {
            arr[index] = document.getElementById(dn).offsetTop;

            height[index] = document.getElementById(dn).offsetHeight;
          }
        }

        time += 500;
        if (arr[arr.length - 1] == that.scrollArr[arr.length - 1]) {
          clearTimeout(chearTime);
          window.addEventListener("scroll", this.scrollMove);
        } else {
          that.scrollArr = arr;
          that.scrollHeight = height;
          this.searchDom(mode);
        }
      }, time);
    },
    getModelPath(data) {
      getModelPath(data, this);
    },
    getModelIdVal,
    setRem,
    skipModel(anchor) {
      if (anchor) {
        this.scrollAnimation(
          document.documentElement.scrollTop || document.body.scrollTop,
          document.getElementById(anchor.trim()).offsetTop - this.lift_height
        );

        // window.scrollTo({
        //   top: document.getElementById(anchor).offsetTop - this.lift_height,
        //   behavior: "smooth",
        // });
      }
      // 找到锚点
      // if (anchor) {
      //   document.getElementById(anchor).scrollIntoView({
      //     behavior: "smooth",
      //   });
      // }
    },
    scrollAnimation(currentY, targetY) {
      //currentY  当前滑动高度       targetY  目标滑动高度
      // 获取当前位置方法
      // const currentY = document.documentElement.scrollTop || document.body.scrollTop
      // 计算需要移动的距离
      let needScrollTop = targetY - currentY;
      let time = null;
      let _currentY = currentY;
      clearTimeout(time);
      time = setTimeout(() => {
        // 一次调用滑动帧数，每次调用会不一样
        clearTimeout(time);
        const dist = Math.ceil(needScrollTop / 10);
        _currentY += dist;
        window.scrollTo(_currentY, currentY);
        // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
        if (needScrollTop > 10 || needScrollTop < -10) {
          this.scrollAnimation(_currentY, targetY);
        } else {
          window.scrollTo(_currentY, targetY);
          this.scrollBoo = true;
        }
      }, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.lift-text_card {
  display: flex;
}
.lift-text_box {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
<style lang="scss">
.van-sticky--fixed {
  z-index: 100;
}
.cardLift {
  .van-sticky--fixed {
    position: sticky !important;
  }
}
</style>
