<template>
  <div id="">
    <div
      :style="{
        background: getModelIdVal(moData, 'bgcolor'),
        'padding-top': setRem(getModelIdVal(moData, 'top')),
        'padding-bottom': setRem(getModelIdVal(moData, 'bottom')),
      }"
    >
      <template v-for="(items, i) in Number(getModelIdVal(moData, 'row'))">
        <div class="icon-box flex-container">
          <div
            class="icon-box-li"
            @click="getModelPath(moData.swiper[i * 5 + ii])"
            :class="
              getModelIdVal(moData, 'pos') == 1
                ? 'flex1'
                : 'fix' + getModelIdVal(moData, 'pos')
            "
            v-for="(cols, ii) in Number(getModelIdVal(moData, 'col'))"
            :key="ii"
          >
            <img
              :src="
                moData.swiper &&
                  moData.swiper[i * 5 + ii] &&
                  moData.swiper[i * 5 + ii].link
              "
              :style="{
                opacity:
                  moData.swiper &&
                  moData.swiper[i * 5 + ii] &&
                  moData.swiper[i * 5 + ii].link
                    ? 1
                    : 0,
              }"
            />
            <p
              :style="{
                'font-size': setRem(getModelIdVal(moData, 'size') * 2),
                color: getModelIdVal(moData, 'color'),
              }"
            >
              {{
                moData.swiper &&
                  moData.swiper[i * 5 + ii] &&
                  moData.swiper[i * 5 + ii].title
              }}
            </p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { setRem, getModelPath, getModelIdVal } from "../../js";
export default {
  name: "",
  data: () => ({}),
  props: {
    moData: {},
  },
  methods: {
    getModelPath(data) {
      getModelPath(data, this);
    },
    getModelIdVal,
    setRem,
  },
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";
@import "../fonts/icon.css";

// 金刚区
.icon-box {
  box-sizing: border-box;
  padding: 0 rem(10);
}
.fix2 {
  width: rem(187.5);
}
.fix3 {
  width: rem(150);
}
.icon-box-li {
  img {
    margin: 0 auto;
    width: rem(100);
    height: rem(100);
    display: block;
    border-radius: 50%;
  }
  p {
    font-size: rem(12);
    text-align: center;
    margin: rem(12) 0;
  }
}
</style>
