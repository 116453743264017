<template>
  <div>
    <img
      class="mo_image"
      @click="getModelPath(moData.swiper[0])"
      :src="moData.swiper[0].link"
    />
    <!-- <template v-if="moData.move">
      <img
        class="fixed_img"
        :id="`fixed_img-${index}`"
        :src="m.img"
        v-for="(m, index) in moData.move"
        :key="index"
        :class="fGetMove(m.moveModel, `fixed_img-${index}`)"
        :style="{
          width: m.width + 'px',
          height: m.height + 'px',
          top: m.top + 'px',
          left: m.left + 'px',
          zIndex: m.zindex,
        }"
      />
    </template> -->
  </div>
</template>
<script>
import { setRem, getModelPath, getModelIdVal } from "../../js";
export default {
  name: "",
  data: () => ({}),
  created() {
    //do something after creating vue instance
    // console.log(this.moData);
  },
  props: {
    moData: {},
  },
  created() {
    console.log();
  },
  methods: {
    fGetMove(val, dom) {
      this.$nextTick(() => {
        // setTimeout(() => {
        document.getElementById(dom).classList.add("move_translateY_top");
        // }, 2000);

        var v = val;
        if (val == 0) {
          return "";
        } else if (val == 1) {
          return "move_translateX_right";
        } else if (val == 2) {
          return "move_translateX_left";
        } else if (val == 3) {
          return "move_translateY_bottom";
        } else if (val == 4) {
          return "move_translateY_top";
        }
      });
    },
    getModelPath(data) {
      getModelPath(data, this);
    },
    getModelIdVal,
    setRem,
  },
};
</script>
<style lang="scss" scoped>
.mo_image {
  width: 100%;
}

.move_translateX_right {
  // transition: 1000ms;
  transform: translateX(100px);
  transition-duration: 5s;
  transition-delay: 5s;
}
.move_translateX_left {
  // transition: 1000ms;

  transform: translateX(-100px);
  transition-duration: 5s;
  transition-delay: 5s;
}
.move_translateY_top {
  // transition: 1000ms;
  transform: translateY(100px);
  transition-duration: 5s;
  transition-delay: 5s;
}
.move_translateY_bottom {
  transform: translateY(-100px);
  transition-duration: 5s;
  transition-delay: 5s;
}
.fixed_img {
  position: absolute;
}
</style>
