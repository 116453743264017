<template>
  <div id="hots">
    <!-- <img :src="getModelPath(moData,'img')" class='hots_bg'>  -->
    <img
      :src="getModelIdVal(moData, 'img')"
      class="hots_bg"
      @click="
        activeNames.length > 0 ? (activeNames = []) : (activeNames = ['1'])
      "
    />
    <van-collapse v-model="activeNames" @change="changeActive">
      <van-collapse-item title="" name="1">
        <hots :moData="moData"></hots>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>
<script>
import hots from "../hots";
import { setRem, getModelPath, getModelIdVal } from "../../js";
import { Collapse, CollapseItem } from "vant";

export default {
  name: "",
  props: {
    moData: {},
  },
  data: () => ({
    activeNames: [],
  }),
  created() {
    // console.log(this.moData);
  },
  components: {
    vanCollapse: Collapse,
    vanCollapseItem: CollapseItem,
    hots,
  },
  methods: {
    changeActive(e) {
      console.log(e);
    },
    getModelPath(data) {
      getModelPath(data, this);
    },
    getModelIdVal,
    setRem,
  },
};
</script>
<style lang="scss" scoped>
#hots {
  position: relative;
  .hots_area {
    position: absolute;
    z-index: 99;
    // background: #000
  }
}
.hots_bg {
  width: 100%;
  display: block;
}

::v-deep .van-collapse-item__title {
  display: none;
}
</style>

<style lang="scss"></style>
