<template>
  <div
    class="countdown-time"
    v-if="src && getModelIdVal(moData, 'time')"
    @click="go(getModelIdVal(moData, 'text'))"
  >
    <img class="mo_image" :src="src" />

    <div
      v-if="changeBoo"
      class="ct-wrap"
      :style="{
        top: getModelIdVal(moData, 'top') + '%',
        left: getModelIdVal(moData, 'left') + '%',
      }"
    >
      <van-count-down
        @finish="finish"
        :time="Number(getModelIdVal(moData, 'time')) - Number(nowTime)"
        :style="{
          fontSize: setRem(getModelIdVal(moData, 'size') * 2),
        }"
      >
        <template #default="timeData">
          <span
            v-if="getModelIdVal(moData, 'mode') == 1"
            :style="{
              color: getModelIdVal(moData, 'color'),
              width: setRem(getModelIdVal(moData, 'width') * 2),
              lineHeight: setRem(getModelIdVal(moData, 'height') * 2),
              borderRadius: setRem(getModelIdVal(moData, 'radius') * 2),
              height: setRem(getModelIdVal(moData, 'height') * 2),
              background: getModelIdVal(moData, 'bgcolor'),
            }"
            >{{ timeData.days }}</span
          >
          <b
            v-if="
              getModelIdVal(moData, 'mode') == 1 &&
                getModelIdVal(moData, 'show') != 3
            "
            :style="{
              color: getModelIdVal(moData, 'descolor'),
              fontSize: setRem(getModelIdVal(moData, 'dessize') * 2),
            }"
            >{{ getModelIdVal(moData, "show") == "2" ? " : " : "天" }}</b
          >
          <span
            :style="{
              color: getModelIdVal(moData, 'color'),
              width: setRem(getModelIdVal(moData, 'width') * 2),
              lineHeight: setRem(getModelIdVal(moData, 'height') * 2),
              borderRadius: setRem(getModelIdVal(moData, 'radius') * 2),
              background: getModelIdVal(moData, 'bgcolor'),
              height: setRem(getModelIdVal(moData, 'height') * 2),
            }"
            >{{ timeData.hours }}</span
          >
          <b
            v-if="getModelIdVal(moData, 'show') != 3"
            :style="{
              color: getModelIdVal(moData, 'descolor'),
              fontSize: setRem(getModelIdVal(moData, 'dessize') * 2),
            }"
            >{{ getModelIdVal(moData, "show") == "2" ? " : " : "时" }}</b
          >
          <span
            :style="{
              color: getModelIdVal(moData, 'color'),
              width: setRem(getModelIdVal(moData, 'width') * 2),
              lineHeight: setRem(getModelIdVal(moData, 'height') * 2),
              borderRadius: setRem(getModelIdVal(moData, 'radius') * 2),
              background: getModelIdVal(moData, 'bgcolor'),
              height: setRem(getModelIdVal(moData, 'height') * 2),
            }"
            >{{ timeData.minutes }}</span
          ><b
            v-if="getModelIdVal(moData, 'show') != 3"
            :style="{
              color: getModelIdVal(moData, 'descolor'),
              fontSize: setRem(getModelIdVal(moData, 'dessize') * 2),
            }"
            >{{ getModelIdVal(moData, "show") == "2" ? " : " : "分" }}</b
          >
          <span
            :style="{
              color: getModelIdVal(moData, 'color'),
              width: setRem(getModelIdVal(moData, 'width') * 2),
              height: setRem(getModelIdVal(moData, 'height') * 2),
              borderRadius: setRem(getModelIdVal(moData, 'radius') * 2),
              background: getModelIdVal(moData, 'bgcolor'),
              lineHeight: setRem(getModelIdVal(moData, 'height') * 2),
            }"
            >{{ timeData.seconds }}</span
          ><b
            v-if="getModelIdVal(moData, 'show') != 3"
            :style="{
              color: getModelIdVal(moData, 'descolor'),
              fontSize: setRem(getModelIdVal(moData, 'dessize') * 2),
            }"
            >{{ getModelIdVal(moData, "show") == "2" ? "" : "秒" }}
          </b>
        </template>
      </van-count-down>
    </div>
  </div>
</template>
<script>
import { setRem, getModelPath, getModelIdVal } from "../../js";
import { CountDown } from "vant";
export default {
  name: "",
  data: () => ({
    src: "",
    changeBoo: true,
    nowTime: Math.round(new Date().getTime()),
  }),
  components: {
    VanCountDown: CountDown,
  },
  created() {
    this.src = getModelIdVal(this.moData, "img");
  },
  props: {
    moData: {},
  },
  methods: {
    go(href) {
      window.location.href = href;
    },
    finish() {
      if (getModelIdVal(this.moData, "endimg")) {
        this.src = getModelIdVal(this.moData, "endimg");
        this.changeBoo = false;
      }
    },
    getModelPath(data) {
      getModelPath(data, this);
    },
    getModelIdVal,
    setRem,
  },
};
</script>
<style lang="scss" scoped>
.mo_image {
  width: 100%;
}
.countdown-time {
  position: relative;

  .ct-wrap {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 12px;
    b {
      font-weight: normal;
      font-size: 12px;
    }
    span {
      display: inline-block;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
    }
  }
}
</style>
