var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    margin: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'topBottomPadding')) + ' 0',
  }),attrs:{"id":"moSwiper"}},[_c('div',{staticClass:"ms",staticStyle:{"overflow":"hidden","position":"relative"},style:({
      height: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'bgheight')),
    })},[(
        _vm.getModelIdVal(_vm.moData, 'beforeimg') &&
          _vm.getModelIdVal(_vm.moData, 'beforeimg').length != 0
      )?_c('img',{staticClass:"swiper_before_img",style:({
        top: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'beforetop')),
        left: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'beforeleft')),
        width: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'beforewidth')),
        height: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'beforeheight')),
      }),attrs:{"src":_vm.getModelIdVal(_vm.moData, 'beforeimg')}}):_vm._e(),_c('van-swipe',{style:({
        height: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'bgheight')),
        paddingTop: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'top')),
        background: 'url(' + _vm.getModelIdVal(_vm.moData, 'img') + ') no-repeat',
        backgroundSize: '100%',
      }),attrs:{"autoplay":_vm.getModelIdVal(_vm.moData, 'speed'),"showIndicators":_vm.getModelIdVal(_vm.moData, 'cur') == 2 ? true : false},on:{"change":_vm.onChange}},_vm._l((_vm.moData.swiper),function(item,index){return (_vm.moData.swiper)?_c('van-swipe-item',{key:index},[_c('div',{staticClass:"finalImage",style:({
            padding: '0 ' + _vm.setRem(_vm.getModelIdVal(_vm.moData, 'leftRigthPadding')),
          })},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.link),expression:"item.link"}],style:({
              width: '100%',
              height: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'height')),
            }),attrs:{"src":item.link},on:{"click":function($event){return _vm.getModelPath(item)}}})])]):_vm._e()}),1),(_vm.getModelIdVal(_vm.moData, 'istip') == 1)?[_c('img',{staticClass:"swiper-jt-next",style:({
          width: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'tipWidth')),
          top: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'tipTop')),
          right: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'tipLeft')),
        }),attrs:{"src":_vm.getModelIdVal(_vm.moData, 'tipImgRight')}}),_c('img',{staticClass:"swiper-jt-prev",style:({
          width: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'tipWidth')),
          top: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'tipTop')),
          left: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'tipLeft')),
        }),attrs:{"src":_vm.getModelIdVal(_vm.moData, 'tipImgLeft')}})]:_vm._e()],2),(_vm.getModelIdVal(_vm.moData, 'current'))?_c('div',{staticClass:"current"},_vm._l((_vm.moData.swiper),function(item,index){return (_vm.moData.swiper)?_c('div',{key:index,staticClass:"c_li",class:_vm.current == index ? 'c_act' : ''}):_vm._e()}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }