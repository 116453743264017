<template>
  <div class="study-live" v-if="liveList && liveList.length != 0">
    <oTitle
      title="直播"
      v-if="liveList && liveList.length > 1"
      :ismore="liveList.length > 1"
      @go="goRouter('Home_LiveList')"
    ></oTitle>
    <div class="live-data" @click="goHref(liveList[0].page_url)">
      <div class="live-1">
        <img :src="liveList[0].img_url" />
        <i class="act">直播中</i>
      </div>
      <div class="live-info">
        <h1 class="live-title">
          {{ liveList[0].title }}
        </h1>
        <span class="live-sub">免费</span>
      </div>
    </div>
  </div>
</template>

<script>
import oTitle from "../../title.vue";
import { post } from "@get/http";
export default {
  components: {
    oTitle,
  },
  data() {
    return {
      liveList: [],
    };
  },
  methods: {
    goHref(src) {
      if (src) {
        window.location.href = src;
      }
    },
    goRouter(name) {
      this.$router.push({
        name,
      });
    },
  },

  async created() {
    let { data } = await post(
      "/lw.Xiaoet/api",
      {
        data: {
          api_url: "https://api.xiaoe-tech.com/xe.alive.list.get/1.0.0",
          method: "POST",
          data: {
            alive_play_state: 1,
            page_size: 2,
          },
        },
      },
      { result: true, toast: false }
    );
    if (data.list && data.list.length != 0) {
      this.liveList = data.list;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.study-live {
  padding: rem(32) rem(30) rem(24);
  box-sizing: border-box;
  .live-data {
    margin: rem(25) auto 0;
    width: rem(690);
    position: relative;
    .live-1 {
      width: rem(690);
      height: rem(296);
      > i {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: rem(16) 0px;
        color: #ffffff;
        font-size: rem(24);
        background: #bdbdbd;
        padding: rem(4) rem(12);
      }
      .act {
        background: #ff4848;
      }
      > img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: rem(16) rem(16) 0 0;
      }
    }
    .live-info {
      background: #fff;
      padding: 0 rem(24);
      height: rem(142);
      border-radius: 0 0 rem(16) rem(16);
    }
    .live-title {
      @include ell;
      font-weight: bold;
      font-size: rem(26);
      line-height: rem(36);
      color: #222222;
      padding: rem(24) 0 rem(16);
    }
    .live-sub {
      color: #989898;
      font-size: rem(24);
    }
  }
}
</style>
