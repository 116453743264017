<template>
  <div>
    <!-- 搜索 -->

    <div
      :style="{
        padding: setRem(getModelIdVal(moData, 'inside')) + ' 0',
        margin: setRem(getModelIdVal(moData, 'outside')) + ' 0',
        background: getModelIdVal(moData, 'bgcolor'),
      }"
    >
      <div
        class="search_id flex-container"
        :style="{
          border:
            (getModelIdVal(moData, 'borderSize') || 0) +
              'px solid ' +
              getModelIdVal(moData, 'borderColor') || '#ff4848',
        }"
      >
        <i
          class="icon1-search"
          :style="{
            color: getModelIdVal(moData, 'iconColor'),
          }"
        ></i>
        <div class="search_input">
          <p
            v-if="moData.txt && moData.txt[0] && moData.txt[0].val == ''"
            @click="onSearch('')"
            :style="{
              color: getModelIdVal(moData, 'color'),
              'font-size': setRem(getModelIdVal(moData, 'size') * 2),
            }"
          >
            请输入您要搜索的产品
          </p>

          <slider
            class="swipers"
            ref="slider"
            v-else
            :options="options"
            @slide="slide"
            @tap="onTap"
            @init="onInit"
          >
            <!-- 直接使用slideritem slot -->
            <slideritem
              v-if="item.val"
              v-for="(item, index) in moData.txt"
              :key="index"
              :style="{
                color: getModelIdVal(moData, 'color'),
                'font-size': setRem(getModelIdVal(moData, 'size') * 2),
              }"
            >
              <span style="width: 100%" @click="onSearch(item.val)">{{
                item.val
              }}</span>
            </slideritem>
            <!-- 设置loading,可自定义 -->
            <div slot="loading">loading...</div>
          </slider>
        </div>
        <div
          class="search_btn"
          @click="onSearch('')"
          v-if="getModelIdVal(moData, 'searchBtn')"
          :style="{
            background: getModelIdVal(moData, 'searchBtnColor'),
          }"
        >
          搜索
        </div>
      </div>
    </div>
    <oSearch :boo.sync="SearchShow"></oSearch>
  </div>
</template>
<script>
import { slider, slideritem } from "vue-concise-slider";
import { setRem, getModelPath, getModelIdVal } from "../../js";
import oSearch from "@view/home/view/goodsSearch/setup.vue";
export default {
  name: "",
  components: {
    slider,
    slideritem,
    oSearch,
  },

  data: () => ({
    //Sliding configuration [obj]
    SearchShow: false,
    options: {
      // currentPage: 0,
      // thresholdDistance:500,
      // thresholdTime:100,
      autoplay: 3000,
      loop: true,
      direction: "vertical",
      // loopedSlides:1,
      // slidesToScroll:1,
      // timingFunction: 'ease',
      // speed: 1000
    },
  }),
  props: {
    moData: {},
  },

  methods: {
    setRem,
    onSearch(key) {
      this.SearchShow = true;
      // this.$router.push({
      //   name: "HomeGoodsSearch",

      //   query: { key },
      // });
    },
    getModelPath(data) {
      getModelPath(data, this);
    },
    getModelIdVal,
    setRem,
    slide(data) {
      // console.log(data)
    },
    onTap(data) {
      // console.log(data)
    },
    onInit(data) {
      // console.log(data)
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";
//搜索
.place_search {
  // background: #ff4747;
}

.icon1-search:before {
  content: "\e900";
  font-size: 15px;
  display: inline-block;
}

.search_id {
  // width: rem(690);
  width: 92%;
  margin: 0 auto;
  height: rem(72);
  background: #fff;
  border-radius: rem(36);
  align-items: center;
  .swipers {
    height: 100%;
    color: #000;
  }
  .icon1-search {
    // color: #ff4747;
    margin-left: rem(20);
  }
  .search_input {
    height: 100%;
    color: #999;
    // width: 100%;
    // display: inline-block;
    margin-left: rem(5);
    flex: 1;
    span {
      text-align: left;
    }
    p {
      line-height: rem(72);
    }
  }
  .search_btn {
    width: rem(132);
    height: rem(60);
    border-radius: rem(30);
    text-align: center;
    line-height: rem(60);
    color: #ffffff;
    font-weight: 400;
    font-size: rem(28);
    margin-right: rem(6);
  }
}
.slider-item {
  color: #000;
}
</style>
<style media="screen">
.slider-pagination {
  display: none;
}
</style>
