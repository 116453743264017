var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"listData_tab"},[_c('div',{attrs:{"id":"listData"}},[_c('div',{ref:"tab",staticClass:"place_area",style:({
        background: 'rgba(255,255,255,' + _vm.bg + ')',
      })},[_c('oTabs',{attrs:{"direction":"x","activeIndex":_vm.active},on:{"scroll":_vm.isFixed}},_vm._l((_vm.moData.tab.content),function(items,i){return _c('li',{key:items.key,staticClass:"li1",class:{
            'span-act': _vm.active == i,
          },on:{"click":function($event){return _vm.changeActive(items, i)}}},[_vm._v(" "+_vm._s(items.alias || items.value)+" "),(_vm.active == i)?_c('span',{staticClass:"solid"}):_vm._e()])}),0)],1),(_vm.moData.tab && _vm.moData.tab.content)?_c('div',[_c('swiper',{ref:"Right_Swiper",staticClass:"swiper-container-right",attrs:{"auto-update":true,"auto-destroy":true,"delete-instance-on-destroy":true,"cleanup-styles-on-destroy":true,"options":_vm.rightTitle}},_vm._l((_vm.moData.tab.content),function(s,si){return _c('swiper-slide',{key:si},[(_vm.getModelIdVal(_vm.moData, 'style') == 5)?_c('div',{staticClass:"study_ul"},[(
                _vm.lists &&
                  _vm.lists[si] &&
                  _vm.lists[si].data &&
                  _vm.lists[si].data.length != 0
              )?_vm._l((_vm.lists[si].data),function(item,index){return _c('div',{key:index,staticClass:"study",on:{"click":function($event){return _vm.goProductPage(item.goods_id)}}},[_c('img',{staticClass:"mainImage",attrs:{"src":((item.goodsimage_full_url) + "?x-oss-process=image/resize,w_200,h_200")}}),_c('div',{staticClass:"content"},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(item.goods_name))]),_c('div',{staticClass:"dsc "},[_vm._v(" "+_vm._s(item.goods_advword)+" ")]),_c('div',{staticClass:"other"},[_c('div',{staticClass:"price"},[_c('div',{staticClass:"now"},[_vm._v(" ¥ "+_vm._s(item.promotion_price ? parseFloat(item.goods_promotion_price) || parseFloat(item.promotion_price) : parseFloat(item.goods_price))+" ")]),_c('div',{staticClass:"mark"},[_vm._v(" ￥"+_vm._s(parseFloat(item.goods_marketprice))+" ")])]),_c('div',{staticClass:"book"},[_vm._v(" "+_vm._s(item.goods_salenum + item.goods_vr_salenum)+"人报名 ")])])])])}):_vm._e()],2):_vm._e(),(
              _vm.getModelIdVal(_vm.moData, 'style') == 3 ||
                _vm.getModelIdVal(_vm.moData, 'style') == 4
            )?_c('div',{staticClass:"dataModel_type3"},[(
                _vm.lists &&
                  _vm.lists[si] &&
                  _vm.lists[si].data &&
                  _vm.lists[si].data.length != 0
              )?_vm._l((_vm.lists[si].data),function(item,index){return _c('div',{key:index,staticClass:"product-list",on:{"click":function($event){return _vm.goProductPage(item.goods_id)}}},[_c('div',{staticClass:"product-img"},[_c('img',{staticClass:"product-img",attrs:{"src":((item.goodsimage_full_url) + "?x-oss-process=image/resize,w_230,h_230")}})]),_c('div',{staticClass:"product-info"},[_c('p',{staticClass:"title"},[_vm._l((item._label),function(label){return (item._label && item._label.length != 0)?_c('span',{staticClass:"tab"},[_vm._v(_vm._s(label))]):_vm._e()}),_vm._v(_vm._s(item.goods_name)+" ")],2),_c('div',{staticClass:"tabs"},[_c('div',{staticClass:"sale"},[_vm._v(" 月销"+_vm._s(_vm.getSaleNumber( item.goods_vr_salenum + item.goods_salenum ))+" ")]),(item._tags && item._tags.length != 0)?_vm._l((item._tags),function(tags){return _c('span',{staticClass:"_item"},[_vm._v(_vm._s(tags))])}):_vm._e()],2),(_vm.getModelIdVal(_vm.moData, 'style') == 3)?_c('div',{staticClass:"product_price"},[_c('b',{staticClass:"nowPrice"},[_vm._v("¥ "+_vm._s(item.promotion_price ? parseFloat(item.goods_promotion_price) || parseFloat(item.promotion_price) : parseFloat(item.goods_price)))]),_c('span',{staticClass:"linePrice"},[_vm._v("¥"+_vm._s(parseFloat(item.goods_marketprice)))])]):_vm._e(),(_vm.getModelIdVal(_vm.moData, 'style') == 4)?_c('div',{staticClass:"product_price2"},[_c('b',{staticClass:"nowPrice"},[_vm._v("¥ "+_vm._s(item.promotion_price ? parseFloat(item.goods_promotion_price) || parseFloat(item.promotion_price) : parseFloat(item.goods_price)))]),_c('span',{staticClass:"linePrice"},[_vm._v("¥"+_vm._s(parseFloat(item.goods_marketprice)))]),_c('div',{staticClass:"buy"},[_vm._v("立即购买")])]):_vm._e()])])}):_vm._e()],2):_vm._e()])}),1)],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }