<template>
  <!-- :style='{
    "padding" :  rem(getModelIdVal(moData,"outside")) + " 0",
    "background" : getModelIdVal(moData,"bgcolor") ,
  }' -->
  <div>
    <template>
      <van-notice-bar
        mode="closeable"
        :scrollable="scrollable"
        :background="getModelIdVal(moData, 'bgcolor')"
        :color="getModelIdVal(moData, 'color')"
        left-icon="volume-o"
        :text="text"
        @replay="replay"
      ></van-notice-bar>
    </template>
  </div>
</template>

<script>
import { setRem, getModelPath, getModelIdVal } from "../../js";
import { NoticeBar } from "vant";
export default {
  name: "",
  data: () => ({
    //Image list
    someList: [],
    scrollable: true,
    text: "",
    boo: null,
  }),
  components: {
    vanNoticeBar: NoticeBar,
  },
  props: {
    moData: {},
  },

  created() {
    this.text = this.getModelIdVal(this.moData, "text");
    //do something after creating vue instance
    // console.log(this.moData);
  },
  methods: {
    replay(e) {
      this.text = "";
      this.scrollable = false;
      clearTimeout(this.boo);
      this.boo = setTimeout(() => {
        this.text = this.getModelIdVal(this.moData, "text");
        this.scrollable = true;
        clearTimeout(this.boo);
      }, 100);
    },
    getModelPath(data) {
      getModelPath(data, this);
    },
    getModelIdVal,
    setRem,
  },
};
</script>
<style lang="scss"></style>
