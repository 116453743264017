var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('van-sticky',{class:{
        cardLift: _vm.getModelIdVal(_vm.moData, 'fixed') == 1 ? 'cardLift' : '',
      }},[_c('div',{ref:"lift",staticClass:"lift-text_card"},[(_vm.getModelIdVal(_vm.moData, 'mode') == 1)?_vm._l((_vm.getModelIdVal(_vm.moData, 'col')),function(item,index){return _c('img',{key:index,staticStyle:{"width":"100%","vertical-align":"top","height":"100px"},style:({
              width: 100 / _vm.getModelIdVal(_vm.moData, 'col') + '%',
              height: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'height') || 100),
            }),attrs:{"src":_vm.active != index
                ? _vm.moData.tabsImgs[index].default
                : _vm.moData.tabsImgs[index].click},on:{"click":function($event){$event.preventDefault();_vm.active = index;
              _vm.scrollBoo = false;
              _vm.skipModel(_vm.moData.tabsImgs[index].name);}}})}):_vm._l((_vm.getModelIdVal(_vm.moData, 'col')),function(item,index){return _c('a',{key:index,staticClass:"lift-text_box",style:({
              width: 100 / _vm.getModelIdVal(_vm.moData, 'col') + '%',
              height: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'height') || 100),
              background:
                _vm.active != index
                  ? _vm.moData.tabsPublicSet.bg
                  : _vm.moData.tabsTexts[index].bg,
              color: _vm.moData.tabsPublicSet.color,
              fontSize: _vm.setRem(_vm.moData.tabsPublicSet.size * 2),
            }),on:{"click":function($event){$event.preventDefault();_vm.active = index;
              _vm.scrollBoo = false;
              _vm.skipModel(_vm.moData.tabsTexts[index].name);}}},[_c('span',{style:({
                color: _vm.active != index ? '' : _vm.moData.tabsTexts[index].color,
              })},[_vm._v(_vm._s(_vm.moData.tabsTexts[index].text))])])})],2)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }