<template>
  <!-- 区域滑块图片 -->
  <div class="place_area mo-place">
    <div
      class="area-box x"
      v-if="getModelIdVal(moData, 'mode') == 1"
      :style="{
        height: size(getModelIdVal(moData, 'height')),
        background: getModelIdVal(moData, 'img')
          ? `url('${getModelIdVal(moData, 'img')}') no-repeat`
          : getModelIdVal(moData, 'bgcolor'),
        backgroundSize: '100% ' + size(getModelIdVal(moData, 'height')),
      }"
    >
      <!--  -->
      <template
        v-for="(items, i) in moData.swiper.length == 0 ? 1 : moData.swiper"
      >
        <img
          @click="getModelPath(items)"
          :src="items && items.link"
          :key="i"
          :style="{
            width: size(moData.swiper[i].width),
            height: size(moData.swiper[i].height),
            marginTop: size(getModelIdVal(moData, 'inside')),
          }"
        />
      </template>
    </div>

    <div
      class="area-box y"
      v-else-if="getModelIdVal(moData, 'mode') == 2"
      :style="{
        height: getModelIdVal(moData, 'height') / 2 + 'px',
      }"
    >
      <template
        v-for="(items, ii) in moData.swiper.length == 0 ? 1 : moData.swiper"
      >
        <img
          :src="items && items.link"
          style="width: 100%"
          :key="ii"
          @click="getModelPath(items)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { setRem, getModelPath, getModelIdVal } from "../../js";
export default {
  data: () => ({}),
  props: {
    moData: {},
  },
  methods: {
    size(val) {
      if (val) {
        return this.setRem(val);
      } else {
        return "100%";
      }
    },
    getModelPath(data) {
      getModelPath(data, this);
    },
    getModelIdVal,
    setRem,
  },
};
</script>

<style lang="scss" scoped>
.place_area {
  .x {
    overflow-y: hidden;
    overflow-x: scroll;
    justify-content: space-between;
    white-space: nowrap;
    img {
      // height: 100%;
    }
    // display: flex;
  }
  .y {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .area-box {
    width: 100%;
    &::-webkit-scrollbar {
    }
  }
}
</style>
