var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{style:({
      padding: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'inside')) + ' 0',
      margin: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'outside')) + ' 0',
      background: _vm.getModelIdVal(_vm.moData, 'bgcolor'),
    })},[_c('div',{staticClass:"search_id flex-container",style:({
        border:
          (_vm.getModelIdVal(_vm.moData, 'borderSize') || 0) +
            'px solid ' +
            _vm.getModelIdVal(_vm.moData, 'borderColor') || '#ff4848',
      })},[_c('i',{staticClass:"icon1-search",style:({
          color: _vm.getModelIdVal(_vm.moData, 'iconColor'),
        })}),_c('div',{staticClass:"search_input"},[(_vm.moData.txt && _vm.moData.txt[0] && _vm.moData.txt[0].val == '')?_c('p',{style:({
            color: _vm.getModelIdVal(_vm.moData, 'color'),
            'font-size': _vm.setRem(_vm.getModelIdVal(_vm.moData, 'size') * 2),
          }),on:{"click":function($event){return _vm.onSearch('')}}},[_vm._v(" 请输入您要搜索的产品 ")]):_c('slider',{ref:"slider",staticClass:"swipers",attrs:{"options":_vm.options},on:{"slide":_vm.slide,"tap":_vm.onTap,"init":_vm.onInit}},[_vm._l((_vm.moData.txt),function(item,index){return (item.val)?_c('slideritem',{key:index,style:({
              color: _vm.getModelIdVal(_vm.moData, 'color'),
              'font-size': _vm.setRem(_vm.getModelIdVal(_vm.moData, 'size') * 2),
            })},[_c('span',{staticStyle:{"width":"100%"},on:{"click":function($event){return _vm.onSearch(item.val)}}},[_vm._v(_vm._s(item.val))])]):_vm._e()}),_c('div',{attrs:{"slot":"loading"},slot:"loading"},[_vm._v("loading...")])],2)],1),(_vm.getModelIdVal(_vm.moData, 'searchBtn'))?_c('div',{staticClass:"search_btn",style:({
          background: _vm.getModelIdVal(_vm.moData, 'searchBtnColor'),
        }),on:{"click":function($event){return _vm.onSearch('')}}},[_vm._v(" 搜索 ")]):_vm._e()])]),_c('oSearch',{attrs:{"boo":_vm.SearchShow},on:{"update:boo":function($event){_vm.SearchShow=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }