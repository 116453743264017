var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"bm-i_list",on:{"click":function($event){return _vm.$router.push({ name: 'bbsMall_Detail', query: { a_id: _vm.item.id } })}}},[_c('div',{staticClass:"bm-i-l_top"},[(_vm.item.image_video_type == 1)?[(
            _vm.item.image_list &&
              _vm.item.image_list[0] &&
              _vm.item.image_list[0].image_url
          )?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
            _vm.item.image_list &&
              _vm.item.image_list[0] &&
              _vm.item.image_list[0].image_url
          ),expression:"\n            item.image_list &&\n              item.image_list[0] &&\n              item.image_list[0].image_url\n          "}],staticClass:"b-i-l-img"}):_vm._e()]:[(_vm.item.video_index_image && _vm.item.video_index_image.image_url)?_c('img',{staticClass:"b-i-l-img",attrs:{"src":_vm.item.video_index_image && _vm.item.video_index_image.image_url}}):_vm._e()],(_vm.item.image_video_type == 2)?_c('img',{staticClass:"playicon",attrs:{"src":require("@image/bbsMall/kk_icon/play_icon.png")}}):_vm._e()],2),_c('div',{staticClass:"bm-i-l_bottom"},[_c('h2',[_vm._v(" "+_vm._s(_vm.item.title)+" ")])]),_c('div',{staticClass:"bm-i-l_control flex-container"},[_c('div',{staticClass:"headImg flex-container"},[_c('img',{attrs:{"src":_vm.item.author && _vm.item.author.author_avatar}}),_c('span',[_vm._v(_vm._s(_vm.item.author && _vm.item.author.author_name))])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }