<template>
  <van-share-sheet
    v-model="sheetVisible"
    title="请选择您要沟通的客服"
    :options="kefuState"
    @select="chatKefu"
    :close-on-click-overlay="overlay"
    get-container="body"
    :cancel-text="cancelText"
  />
</template>

<script>
import { Popup, ShareSheet, Toast } from "vant";
import { setBindingCustomerToSale } from "@set/util";
import { mapState } from "vuex";
import { get } from "@get/http";
export default {
  props: {
    boo: {
      default: false,
      type: Boolean,
    },
    cancelText: {
      default: "取消",
      type: String,
    },
    overlay: {
      default: true,
      type: Boolean,
    },
  },
  async created() {
    let data = await get("/v3.MemberSeller/getSellerList");
    data.list.map((item) => {
      this.kefuState[item.is_online == 1 ? 0 : 1].push({
        ...item,
        icon:
          item.is_online == 1
            ? "http://image.shop.19jiang.cn/home/mofang/alioss_603468fc4fb69.jpg"
            : "http://image.shop.19jiang.cn/home/mofang/alioss_6034695f182dd.png",
      });
    });
    this.kefuState[0].sort(this.randomsort);
    this.sheetVisible = this.boo;
  },
  watch: {
    boo() {
      this.sheetVisible = this.boo;
    },
    sheetVisible() {
      this.$emit("update:boo", this.sheetVisible);
    },
  },
  components: {
    vanPopup: Popup,
    vanShareSheet: ShareSheet,
  },
  data() {
    return {
      sheetVisible: false,
      kefuState: [[], []],
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.info,
      userToken: (state) => state.user.token,
      // kefuState: (state) => state.common.kefu,
    }),
  },
  methods: {
    randomsort(a, b) {
      return Math.random() > 0.5 ? -1 : 1;
      //用Math.random()函数生成0~1之间的随机数与0.5比较，返回-1或1
    },
    async chatKefu(op, index) {
      if (op.is_online === 1) {
        setBindingCustomerToSale(op.shop_ac_id);
      }
      window.open(op.work_link);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-share-sheet__options {
  flex-wrap: wrap;
}
::v-deep .van-share-sheet__option {
  margin-bottom: 10px;
}
</style>
