var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    padding: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'inside')) + ' 0',
    background: _vm.getModelIdVal(_vm.moData, 'bgcolor'),
  })},[_c('pre',{staticClass:"text-content",style:({
      color: _vm.getModelIdVal(_vm.moData, 'color'),
      fontSize: _vm.setRem(_vm.getModelIdVal(_vm.moData, 'size') * 2),
      textAlign: _vm.textAlign(_vm.getModelIdVal(_vm.moData, 'pos')),
    }),domProps:{"innerHTML":_vm._s(_vm.getModelIdVal(_vm.moData, 'text'))}}),(_vm.getModelIdVal(_vm.moData, 'copy') == 1)?_c('div',{staticClass:"place_text_CopyText",on:{"click":function($event){_vm.doCopy(_vm.getModelIdVal(_vm.moData, 'text'))}}},[_vm._v(" 复制文本 ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }