<template>
  <div id="">
    <!-- 两列图片 -->
    <div class="place_twoImg mo-place">
      <img
        v-if="moData.swiper && moData.swiper[0] && moData.swiper[0].link"
        @click="getModelPath(moData.swiper[0])"
        style="width: 50%; display: inline-block; vertical-align: top"
        :src="moData.swiper && moData.swiper[0] && moData.swiper[0].link"
      />
      <img
        @click="getModelPath(moData.swiper[1])"
        v-if="moData.swiper && moData.swiper[1] && moData.swiper[1].link"
        style="width: 50%; display: inline-block; vertical-align: top"
        :src="moData.swiper && moData.swiper[1] && moData.swiper[1].link"
      />
    </div>
  </div>
</template>
<script>
import { setRem, getModelPath, getModelIdVal } from "../../js";
export default {
  name: "",
  data: () => ({}),
  created() {
    //do something after creating vue instance
    // console.log(this.moData);
  },
  props: {
    moData: {},
  },
  methods: {
    getModelPath(data) {
      getModelPath(data, this);
    },
    getModelIdVal,
    setRem,
  },
};
</script>
<style lang="scss" scoped>
.mo_image {
  width: 100%;
}
</style>
