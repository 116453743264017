<template>
  <div>
    <div class="bbs-swiper" v-if="image_list && image_list.length != 0">
      <oTitle title="热点" class="bbs-title" :ismore="false"></oTitle>
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in image_list" :key="index">
          <img
            :src="item.image_url"
            @click="goHref(item.location_url)"
            class="bbs-title-swiper-img"
          />
        </van-swipe-item>
      </van-swipe>
    </div>
    <oTitle
      title="广场"
      class="bbs-title"
      :ismore="false"
      v-if="image_list && image_list.length != 0"
    ></oTitle>
    <div
      style="height:0.5rem"
      v-if="image_list && image_list.length == 0"
    ></div>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <oInfoList :list="formData" :finished="finished"></oInfoList>
    </van-list>
  </div>
</template>

<script>
import oInfoList from "@view/h5/bbsMall/components/infoList/";
import { Swipe, SwipeItem } from "vant";
import { List } from "vant";
import oTitle from "../title";
import { get } from "@get/http";
export default {
  components: {
    oInfoList,
    oTitle,
    vanSwipe: Swipe,
    vanSwipeItem: SwipeItem,
    vanList: List,
  },
  data() {
    return {
      image_list: [],
      formData: [],
      pageSize: 20,
      page: 1,
      loading: false,
      finished: false,
    };
  },
  methods: {
    goHref(src) {
      window.location.href = src;
    },
    async onLoad() {
      let data = await get("/GoodsArticle/index", {
        params: {
          pageSize: this.pageSize,
          page: this.page,
        },
      });
      this.formData.push(...data.data);
      // 加载状态结束
      this.loading = false;
      this.page++;
      if (data.data.length != this.pageSize) {
        this.finished = true;
      }
    },
  },
  async created() {
    let { image_list } = await get("/GoodsArticle/getImage", {
      params: {
        type: 5,
      },
    });
    this.image_list = image_list;
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.bbs-title {
  padding: rem(32) rem(30) rem(24);
}
.bbs-title-swiper-img {
  width: rem(690);
  height: rem(186);
  border-radius: rem(16);
  margin: 0 auto;
  display: block;
}
</style>
