<template>
  <div
    class="Vision"
    @click="getModelPath(moData.swiper[0])"
    :style="{
      '--background': `url('${moData.swiper[0].link}') no-repeat`,
      backgroundSize: 'cover',
    }"
  ></div>
</template>
<script>
import { setRem, getModelPath, getModelIdVal } from "../../js";
export default {
  name: "",
  data: () => ({
    widthVar: "",
  }),
  created() {},
  props: {
    moData: {},
  },
  methods: {
    getModelPath(data) {
      getModelPath(data, this);
    },
    getModelIdVal,
    setRem,
  },
};
</script>
<style lang="scss" scoped>
.Vision {
  width: 100%;
  height: 300px;
  position: relative;
  &::before {
    content: " ";
    position: fixed;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--background);
    background-size: 100%;
    background-attachment: fixed;
  }
}
</style>
