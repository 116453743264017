<template>
  <div class="study-class" v-if="classList && classList.length != 0">
    <oTitle
      v-if="istitle"
      title="训练营"
      class="class-title"
      @go="goRouter('Home_classList')"
    ></oTitle>

    <oBlock :list="classList"></oBlock>
  </div>
</template>

<script>
import oTitle from "../../title.vue";
import oBlock from "../components/block";
import { post } from "@get/http";
import { getClassList } from "../js/index.js";
export default {
  components: {
    oTitle,
    oBlock,
  },
  props: {
    istitle: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      classList: [],
    };
  },
  methods: {
    goRouter(name) {
      this.$router.push({
        name,
      });
    },
  },
  async created() {
    let data = await getClassList({
      type: 9,
      pageSize: 100,
    });

    if (data.list && data.list.length != 0) {
      this.classList = data.list;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.class-title {
  padding: 0 rem(30) rem(24);
}
</style>
