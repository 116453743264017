<template>
  <div style="width: 100%;z-ndex:999" ref="searchBar">
    <div :style="{ height: height + 'px' }" v-if="heightShow"></div>

    <div
      class="xd"
      ref="xd"
      id="searchBar"
      :class="fixedBar ? 'searchBarFixed' : 'searchBarSticky'"
    >
      <div
        v-for="(item, index) in getModelIdVal(moData, 'col')"
        :key="index"
        class="tabImg"
        @click="gotoTab(index)"
        :style="{ width: 100 / getModelIdVal(moData, 'col') + '%' }"
      >
        <img
          :src="
            active != index
              ? moData.tabsImgs[index].default
              : moData.tabsImgs[index].click
          "
          style="width: 100%; vertical-align: top"
        />
      </div>
    </div>

    <van-tabs v-model="active" :lazy-render="true">
      <van-tab
        v-for="i in Number(getModelIdVal(moData, 'col'))"
        :title="'选项 ' + i"
        :key="i"
      >
        <momodel :moData="moData.tabsArrData[active]"></momodel>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
const momodel = () => import("../models/index.vue");
// import abcdefg from '../../components/models/index.vue'
import { setRem, getModelPath, getModelIdVal } from "../../js";
import { Tab, Tabs } from "vant";

export default {
  name: "trees",
  data: () => ({
    //Image list
    active: 0,
    height: "0",
    heightShow: false,
    offsetTops: "0",
    fixedBar: false,
  }),
  components: {
    momodel,
    vanTab: Tab,
    vanTabs: Tabs,
  },
  props: {
    moData: {},
  },

  created() {
    //do something after creating vue instance
    // console.log(this.moData);
  },
  destroyed() {
    if (this.getModelIdVal(this.moData, "fixed") == 2) {
      window.removeEventListener("scroll", this.handleScroll);
    }
  },
  deactivated() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    let that = this;
    if (this.getModelIdVal(this.moData, "fixed") == 2) {
      this.$nextTick(() => {
        setTimeout(() => {
          that.height = that.$refs.xd.offsetHeight;
          that.offsetTops = that.$refs.searchBar.offsetTop;
          window.addEventListener("scroll", that.handleScroll);
        }, 300);
      });
    }
  },

  methods: {
    gotoTab(index) {
      this.active = index;
      if (this.heightShow == true) {
        window.scrollTo({ top: this.offsetTops });
      }
    },
    handleScroll() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // let offsetTop = document.querySelector('#searchBar').offsetTop;
      let offsetTop = this.$refs.searchBar.offsetTop;
      if (scrollTop > offsetTop - 5) {
        // document.querySelector("#searchBar").style.position = "fixed";
        // document.querySelector("#searchBar").style.top = "0";
        this.fixedBar = true;
        this.heightShow = true;
      } else {
        // document.querySelector("#searchBar").style.position = "sticky";
        // document.querySelector("#searchBar").style.top = "0";
        this.fixedBar = false;
        this.heightShow = false;
      }
    },
    getModelPath(data) {
      getModelPath(data, this);
    },
    getModelIdVal,
    setRem,
  },
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";

.flex-container {
  align-items: center;
}

.tabImg {
  display: inline-block;
}
.xd {
  // position: sticky;
  // top:0px;
  z-index: 999;
  // -webkit-transform: translateZ(0);
}
.searchBarFixed {
  position: fixed;
  top: 0;
}
.searchBarSticky {
  position: sticky;
}
</style>
<style lang="scss">
.van-tabs__wrap {
  display: none;
}
</style>
