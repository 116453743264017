<template>
  <div class="index-title">
    <h1>{{ title }}</h1>
    <i v-if="ismore" @click="$emit('go', '')">查看全部</i>
    <svg
      v-if="ismore"
      width="12"
      height="21"
      viewBox="0 0 12 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L10.5 10.5L1 20"
        stroke="#989898"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    ismore: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.index-title {
  display: flex;
  align-items: center;

  > h1 {
    flex: 1;
    color: #222222;
    font-size: rem(34);
    font-weight: bold;
  }
  > i {
    font-size: rem(24);
    color: #989898;
  }
  > svg {
    width: rem(9.5);
    height: rem(19);
    margin-left: rem(6);
  }
}
</style>
