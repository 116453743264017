<template>
  <div class="index-tab">
    <van-tabs
      v-model="active"
      sticky
      class="index-tab-tabs"
      title-active-color="#222"
    >
      <van-tab title="学习" name="study">
        <oStudy></oStudy>
      </van-tab>
      <van-tab title="商城" name="shop">
        <div class="tab-shop">
          <oModel v-if="active == 'shop'"></oModel>
        </div>
      </van-tab>
      <van-tab title="分院" name="branch">
        <div class="tab-branch">
          <oBranch></oBranch>
        </div>
      </van-tab>
      <van-tab title="广场" name="bbs">
        <oBbs></oBbs>
      </van-tab>
      <van-tab title="官网" name="web">
        <iframe
          ref="ifra"
          src="https://www.19jiang.com/"
          frameborder="0"
          class="tab-website"
        ></iframe>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { Tab, Tabs } from "vant";
import merge from "webpack-merge";
import oBranch from "../../../branch.vue";
import oModel from "../../../index.vue";
import oBbs from "./bbs";
import oTitle from "./title.vue";
import oStudy from "./study";
import { getQueryVariable } from "@get/util";
export default {
  data() {
    return {
      active: "shop",
    };
  },
  components: {
    vanTab: Tab,
    vanTabs: Tabs,
    oModel,
    oBbs,
    oTitle,
    oBranch,
    oStudy,
  },
  watch: {
    active() {
      // 刷新链接
      this.$router.push({
        query: merge(this.$route.query, {
          tab: this.active,
        }),
      });
    },
  },
  created() {
    this.active = getQueryVariable("tab") || "shop";
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.index-tab {
  margin-top: rem(-16);
  margin-bottom: rem(200);
}
.index-tab-tabs {
  ::v-deep {
    > div {
      .van-sticky {
        .van-tabs__line {
          width: rem(36);
          border-radius: rem(6);
          height: rem(6);
          bottom: 5px;
        }
        .van-tab__pane {
          box-sizing: border-box;
        }
        > .van-tabs__wrap {
          display: block;
          padding-bottom: rem(10);
          > .van-tabs__nav {
            display: flex;
            align-items: center;
            // padding: 0 rem(30);
            padding-top: rem(28);
            box-sizing: border-box;
            width: 100%;
            height: rem(98);
            flex-wrap: wrap;
            border-radius: rem(24) rem(24) 0 0;
            background: #f8f8f8;
            // border-bottom: 1px solid #e1e1e1;
            .van-tab {
              flex: none;
              margin: 0 rem(10);
              &:first-of-type {
                margin-left: 0;
              }
              display: inline-block;
              width: rem(116) !important;
              border-radius: rem(54/2);
              height: rem(54);
              line-height: rem(54);
              font-size: rem(30);
              color: #666;
              span {
                font-weight: bold;
                text-align: center;
              }
            }

            .van-tab--active {
              // background: #ff4848;
            }
          }
        }
      }
    }
  }
}
.tab-website {
  margin-top: rem(40);
  width: 100%;
  height: 300px;
  box-sizing: border-box;
  padding: 0 rem(30);
}
// .index-tab {
//   display: flex;
//   li {
//     width: rem(116);
//     text-align: center;
//     height: rem(54);
//     color: #666666;
//     font-size: rem(30);
//     line-height: rem(54);
//   }
//   .act {
//     color: #fff;
//     background: #ff4848;
//     border-radius: rem(38);
//   }
// }
</style>
