<template>
  <div
    id="moSwiper"
    :style="{
      margin: setRem(getModelIdVal(moData, 'topBottomPadding')) + ' 0',
    }"
  >
    <div
      class="ms"
      style="overflow: hidden; position: relative"
      :style="{
        height: setRem(getModelIdVal(moData, 'bgheight')),
      }"
    >
      <img
        :src="getModelIdVal(moData, 'beforeimg')"
        v-if="
          getModelIdVal(moData, 'beforeimg') &&
            getModelIdVal(moData, 'beforeimg').length != 0
        "
        class="swiper_before_img"
        :style="{
          top: setRem(getModelIdVal(moData, 'beforetop')),
          left: setRem(getModelIdVal(moData, 'beforeleft')),
          width: setRem(getModelIdVal(moData, 'beforewidth')),
          height: setRem(getModelIdVal(moData, 'beforeheight')),
        }"
      />

      <van-swipe
        @change="onChange"
        :autoplay="getModelIdVal(moData, 'speed')"
        :showIndicators="getModelIdVal(moData, 'cur') == 2 ? true : false"
        :style="{
          height: setRem(getModelIdVal(moData, 'bgheight')),
          paddingTop: setRem(getModelIdVal(moData, 'top')),
          background: 'url(' + getModelIdVal(moData, 'img') + ') no-repeat',
          backgroundSize: '100%',
        }"
      >
        <van-swipe-item
          v-for="(item, index) in moData.swiper"
          v-if="moData.swiper"
          :key="index"
        >
          <div
            class="finalImage"
            :style="{
              padding: '0 ' + setRem(getModelIdVal(moData, 'leftRigthPadding')),
            }"
          >
            <img
              :src="item.link"
              v-lazy="item.link"
              @click="getModelPath(item)"
              :style="{
                width: '100%',
                height: setRem(getModelIdVal(moData, 'height')),
              }"
            />
          </div>
        </van-swipe-item>
      </van-swipe>
      <template v-if="getModelIdVal(moData, 'istip') == 1">
        <img
          :src="getModelIdVal(moData, 'tipImgRight')"
          class="swiper-jt-next"
          :style="{
            width: setRem(getModelIdVal(moData, 'tipWidth')),
            top: setRem(getModelIdVal(moData, 'tipTop')),
            right: setRem(getModelIdVal(moData, 'tipLeft')),
          }"
        />
        <img
          :src="getModelIdVal(moData, 'tipImgLeft')"
          class="swiper-jt-prev"
          :style="{
            width: setRem(getModelIdVal(moData, 'tipWidth')),
            top: setRem(getModelIdVal(moData, 'tipTop')),
            left: setRem(getModelIdVal(moData, 'tipLeft')),
          }"
        />
      </template>
    </div>
    <div class="current" v-if="getModelIdVal(moData, 'current')">
      <div
        class="c_li"
        :class="current == index ? 'c_act' : ''"
        :key="index"
        v-for="(item, index) in moData.swiper"
        v-if="moData.swiper"
      ></div>
    </div>
  </div>
</template>

<script>
import { setRem, getModelPath, getModelIdVal } from "../../js";
import { Swipe, SwipeItem } from "vant";
export default {
  name: "",
  data: () => ({
    current: 0,
  }),
  props: {
    moData: {},
  },
  created() {
    //do something after creating vue instance
    // console.log(this.moData);
  },
  components: {
    vanSwipe: Swipe,
    vanSwipeItem: SwipeItem,
  },
  methods: {
    onChange(index) {
      this.current = index;
    },
    getModelPath(data) {
      getModelPath(data, this);
    },
    getModelIdVal,
    setRem,
  },
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";
.ms {
  margin: 0 auto;
  // width: rem(702);
}
.swiper_before_img {
  pointer-events: none;
  position: absolute;
  z-index: 98;
}

.swiper-jt-next,
.swiper-jt-prev {
  z-index: 9999;
  position: absolute;

  top: 0;
  // bottom: 0;
  // margin: auto;
  width: rem(80);
}
.swiper-jt-next {
  right: 0;
  animation: moveRight 2s infinite ease;
}
.swiper-jt-prev {
  left: 0;
  // transform: rotate(180deg);
  animation: moveLeft 2s infinite ease;
}
@keyframes moveRight {
  0% {
    transform: translateX(-15px);
  }
  50% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-15px);
  }
}

@keyframes moveLeft {
  0% {
    transform: translateX(15px);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(15px);
  }
}

.finalImage {
  padding: 0 rem(24);
  box-sizing: border-box;
  border-radius: rem(16);
  img {
    border-radius: rem(16);
  }
}

.current {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: rem(16) 0 rem(24);
  .c_li {
    margin-right: rem(6);
    width: rem(16);
    height: rem(6);
    background: rgba(0, 0, 0, 0.15);
    border-radius: rem(3);
  }
  .c_act {
    width: rem(24);
    background: #ff4848;
  }
}
</style>
