<template>
  <div class="moZoomSwiper">
    <swiper
      ref="mySwiper"
      :options="{
        ...leftTitle,
        autoplay: {
          disableOnInteraction: false,
          delay: getModelIdVal(moData, 'speed') || 4000,
        },
      }"
      class="swiper-container-left"
      style="z-index: 9999"
      :style="{
        height: setRem(getModelIdVal(moData, 'bgheight')),
        background: 'url(' + getModelIdVal(moData, 'img') + ') no-repeat',
        backgroundSize: '100%',
        paddingTop: setRem(getModelIdVal(moData, 'top')),
      }"
    >
      <swiper-slide
        v-for="(item, index) in moData.swiper"
        v-if="moData.swiper"
        :key="index"
        :style="{
          width: setRem(getModelIdVal(moData, 'width')),
          height: setRem(getModelIdVal(moData, 'height')),
        }"
      >
        <div
          style="width: 100%; height: 100%; position: relative"
          @click="item.childimg ? zoomImg(item) : getModelPath(item)"
        >
          <img
            v-if="item.childimg && item.childimg.length != 0"
            src="http://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2021052814401953062.png"
            class="zoomSiper_zoom"
          />
          <img :src="item.link" v-lazy="item.link" class="zoomSiper_img" />
        </div>
      </swiper-slide>

      <!-- <div class="swiper-pagination" slot="pagination"></div> -->
    </swiper>
    <!-- <div class="swiper-jt"> -->
    <template v-if="getModelIdVal(moData, 'istip') == 1">
      <img
        :src="getModelIdVal(moData, 'tipImgRight')"
        class="swiper-jt-next"
        :style="{
          width: setRem(getModelIdVal(moData, 'tipWidth')),
          top: setRem(getModelIdVal(moData, 'tipTop')),
          right: setRem(getModelIdVal(moData, 'tipLeft')),
        }"
      />
      <img
        :src="getModelIdVal(moData, 'tipImgLeft')"
        class="swiper-jt-prev"
        :style="{
          width: setRem(getModelIdVal(moData, 'tipWidth')),
          top: setRem(getModelIdVal(moData, 'tipTop')),
          left: setRem(getModelIdVal(moData, 'tipLeft')),
        }"
      />
    </template>
    <!-- </div> -->

    <van-popup
      v-model="imgshow"
      get-container="body"
      :style="{ width: '90%' }"
      closeable
      close-on-popstate
    >
      <img
        :src="zoomImgArr"
        style="width: 100%"
        @click="getModelPath(zoomImgHref)"
      />
    </van-popup>
  </div>
</template>

<script>
import { Popup } from "vant";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import { setRem, getModelPath, getModelIdVal } from "../../js";
import "swiper/swiper.scss";
export default {
  data() {
    return {
      imgshow: false,
      zoomImgArr: "",
      zoomImgHref: null,
      leftTitle: {
        loop: true,
        speed: 800,
        // slidesPerView: 3,
        slidesPerView: "auto",
        centeredSlides: true,
      },
    };
  },
  methods: {
    zoomImg(item) {
      if (item.childimg.length != 0) {
        this.imgshow = true;
        this.zoomImgArr = item.childimg;
        this.zoomImgHref = item;
      }
    },
    getModelPath(data) {
      getModelPath(data, this);
    },
    getModelIdVal,
    setRem,
  },
  computed: {
    // swiper() {
    //   return this.$refs.mySwiper.$swiper;
    // },
  },
  mounted() {
    // this.leftTitle.autoplay.delay = this.getModelIdVal(moData, "speed");
  },
  props: {
    moData: {},
  },
  components: {
    Swiper,
    SwiperSlide,
    vanPopup: Popup,
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

.swiper-container {
  margin: 0;
  width: 100%;
  height: 100%;
}

.swiper-container2 .swiper-slide {
  height: auto;
}

// 左边
.swiper-container-left {
  position: relative;
  background: #fff;
  // overflow: hidden;
  width: 100%;
  height: 100%;
  .swiper-slide {
    height: auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    height: rem(200);

    margin: 0 auto;
    .zoomSiper_img {
      display: block;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      transition: 300ms;
      transform: scale(0.8);
    }
    .zoomSiper_zoom {
      position: absolute;
      top: rem(50);
      right: rem(20);
      z-index: 99;
      width: rem(30);
      transition: 300ms;
    }
  }
  .swiper-slide-active {
    // transform: scale(1);
    // margin: 0 rem(20);
    .zoomSiper_img {
      transform: scale(1);
    }
    .zoomSiper_zoom {
      top: rem(10);
      right: rem(0);
      width: rem(40);
    }
  }
}

.swiper-button-next,
.swiper-button-prev {
  position: fixed;
}

.moZoomSwiper {
  position: relative;
  z-index: 99;
}
.swiper-jt {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
.swiper-jt-next,
.swiper-jt-prev {
  z-index: 9999;
  position: absolute;

  // top: 0;
  // bottom: 0;
  // margin: auto;
  width: rem(80);
}
.swiper-jt-next {
  // right: 0;
  animation: moveRight 2s infinite ease;
}
.swiper-jt-prev {
  // left: 0;
  // transform: rotate(180deg);
  animation: moveLeft 2s infinite ease;
}

@keyframes moveRight {
  0% {
    transform: translateX(-15px);
  }
  50% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-15px);
  }
}

@keyframes moveLeft {
  0% {
    transform: translateX(15px);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(15px);
  }
}
</style>
