<template>
  <div ref="listData_tab">
    <div id="listData">
      <div
        class="place_area"
        ref="tab"
        :style="{
          background: 'rgba(255,255,255,' + bg + ')',
        }"
      >
        <oTabs direction="x" :activeIndex="active" @scroll="isFixed">
          <li
            class="li1"
            :class="{
              'span-act': active == i,
            }"
            @click="changeActive(items, i)"
            v-for="(items, i) in moData.tab.content"
            :key="items.key"
          >
            {{ items.alias || items.value }}
            <span class="solid" v-if="active == i"></span>
          </li>
        </oTabs>
      </div>

      <div v-if="moData.tab && moData.tab.content">
        <!--  -->
        <swiper
          :auto-update="true"
          :auto-destroy="true"
          :delete-instance-on-destroy="true"
          :cleanup-styles-on-destroy="true"
          ref="Right_Swiper"
          :options="rightTitle"
          class="swiper-container-right"
        >
          <!-- :style="{ height: swiperHeight + 'px' }" -->

          <swiper-slide v-for="(s, si) in moData.tab.content" :key="si">
            <div class="study_ul" v-if="getModelIdVal(moData, 'style') == 5">
              <template
                v-if="
                  lists &&
                    lists[si] &&
                    lists[si].data &&
                    lists[si].data.length != 0
                "
              >
                <div
                  class="study"
                  @click="goProductPage(item.goods_id)"
                  v-for="(item, index) in lists[si].data"
                  :key="index"
                >
                  <img
                    :src="
                      `${item.goodsimage_full_url}?x-oss-process=image/resize,w_200,h_200`
                    "
                    class="mainImage"
                  />
                  <div class="content">
                    <h1 class="title">{{ item.goods_name }}</h1>
                    <div class="dsc ">
                      {{ item.goods_advword }}
                    </div>
                    <div class="other">
                      <div class="price">
                        <div class="now">
                          ¥
                          {{
                            item.promotion_price
                              ? parseFloat(item.goods_promotion_price) ||
                                parseFloat(item.promotion_price)
                              : parseFloat(item.goods_price)
                          }}
                        </div>
                        <div class="mark">
                          ￥{{ parseFloat(item.goods_marketprice) }}
                        </div>
                      </div>
                      <div class="book">
                        {{ item.goods_salenum + item.goods_vr_salenum }}人报名
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>

            <div
              class="dataModel_type3"
              v-if="
                getModelIdVal(moData, 'style') == 3 ||
                  getModelIdVal(moData, 'style') == 4
              "
            >
              <template
                v-if="
                  lists &&
                    lists[si] &&
                    lists[si].data &&
                    lists[si].data.length != 0
                "
              >
                <div
                  class="product-list"
                  @click="goProductPage(item.goods_id)"
                  v-for="(item, index) in lists[si].data"
                  :key="index"
                >
                  <div class="product-img">
                    <img
                      class="product-img"
                      :src="
                        `${item.goodsimage_full_url}?x-oss-process=image/resize,w_230,h_230`
                      "
                    />
                  </div>

                  <div class="product-info">
                    <p class="title">
                      <span
                        class="tab"
                        v-for="label in item._label"
                        v-if="item._label && item._label.length != 0"
                        >{{ label }}</span
                      >{{ item.goods_name }}
                    </p>
                    <div class="tabs">
                      <div class="sale">
                        月销{{
                          getSaleNumber(
                            item.goods_vr_salenum + item.goods_salenum
                          )
                        }}
                      </div>
                      <template v-if="item._tags && item._tags.length != 0">
                        <span class="_item" v-for="tags in item._tags">{{
                          tags
                        }}</span>
                      </template>
                    </div>

                    <div
                      class="product_price"
                      v-if="getModelIdVal(moData, 'style') == 3"
                    >
                      <b class="nowPrice"
                        >¥
                        {{
                          item.promotion_price
                            ? parseFloat(item.goods_promotion_price) ||
                              parseFloat(item.promotion_price)
                            : parseFloat(item.goods_price)
                        }}</b
                      >
                      <span class="linePrice"
                        >¥{{ parseFloat(item.goods_marketprice) }}</span
                      >
                    </div>

                    <div
                      class="product_price2"
                      v-if="getModelIdVal(moData, 'style') == 4"
                    >
                      <b class="nowPrice"
                        >¥
                        {{
                          item.promotion_price
                            ? parseFloat(item.goods_promotion_price) ||
                              parseFloat(item.promotion_price)
                            : parseFloat(item.goods_price)
                        }}</b
                      >
                      <span class="linePrice"
                        >¥{{ parseFloat(item.goods_marketprice) }}</span
                      >
                      <div class="buy">立即购买</div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { post } from "@get/http";
import oTabs from "./component/tabs.vue";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import { setRem, getModelPath, getModelIdVal } from "../../js";
import { Tab, Tabs, Toast } from "vant";
import smoothscroll from "smoothscroll-polyfill";
export default {
  components: {
    Swiper,
    oTabs,
    SwiperSlide,
    vanTab: Tab,
    vanTabs: Tabs,
  },
  props: {
    moData: {},
  },
  data() {
    return {
      swiperHeight: 2000,
      bg: 0,
      rightTitle: {
        autoHeight: true,
        spaceBetween: 20,
        observer: true,
        observeParents: true,

        on: {
          touchEnd: () => {
            let that = this.swiper_right;
            let index = that.activeIndex; //当前index
            this.lists[index].position = window.scrollY;
          },
          slideChangeTransitionEnd: async () => {
            let that = this.swiper_right;

            let index = that.activeIndex; //当前index
            let previousIndex = that.previousIndex;
            this.active = index;
            this.class_id = this.moData.tab.content[index].key;
            await this.getProductList();
            // this.swiperHeight = that.slides[index].offsetHeight;

            // this.tab_offsetTop = this.$refs.listData_tab?.offsetTop;
          },
        },
      },
      active: 0,
      class_id: 0,
      goods_list: [],
      goods_lists: [],
      lists: [],
    };
  },
  mounted() {
    // smoothscroll.polyfill();
  },
  async created() {
    this.active = this.moData?.tab?.current || 0;

    this.class_id = this.moData?.tab?.content[this.active].key;

    if (this.moData?.tab?.content?.length != 0) {
      for (let index = 0; index < this.moData?.tab?.content.length; index++) {
        this.lists.push({
          position: 0,
          data: [],
        });
      }
      this.getProductList();
    }
  },
  computed: {
    swiper_right() {
      return this.$refs.Right_Swiper.$swiper;
    },
  },

  tab_offsetTop: 0, // 标签距离顶部的距离 用户切换的时候设置默认值
  swiperPosition: [0, 0], // 记录轮播图的滑动距离
  scrollTop: 0,
  methods: {
    getSaleNumber(data) {
      data = data.toString();

      if (data?.length == 1) {
        // 个数
        return "10+";
      }
      if (data?.length == 2) {
        // 十位
        return data[0] + "0+";
      }
      if (data?.length > 2) {
        // 百位
        return (Number(data.slice(0, data.length - 2)) + 1).toString() + "00+";
      }
    },
    getZero(num) {
      let str = "";
      if (num >= 1) {
        for (let index = 0; index < num.length; index++) {
          str += "0";
        }
      }
      return srt;
    },
    isFixed(data) {
      if (data.scrollTop >= this.tab_offsetTop) {
        if (data.scrollTop > this.scrollTop) {
          this.bg = 1;
        }
        this.scrollTop = data.scrollTop;
      } else {
        this.bg = 0;
        this.scrollTop = 0;
      }
    },
    goProductPage(id) {
      this.$router.push({
        name: "HomeGoodsdetail",
        query: { goods_id: id },
      });
    },
    getModelIdVal,
    async getProductList() {
      let d = this.moData?.tab?.content;

      if (d) {
        let index = d.findIndex((item) => item.key == this.class_id);
        if (index != -1) {
          if (this.lists[index] && this.lists[index]?.data.length != 0) {
            // this.goods_list = this.goods_lists[index];
            return;
          }
        }
        let { goods_list } = await post("/lw.Store/store_goods_p_v4", {
          data: {
            page: this.page, //页数
            pageSize: this.getModelIdVal(this.moData, "number") || 200,
            storegc_id: this.class_id, // 二级分类ID
            keyword: "",
            store_id: process.env.VUE_APP_STORE, //店铺ID
            sort_order: this?.order_key != "" ? this?.order_key : "",
            sort_key: this.sort_id, //  按什么排序
            //5是默认     价格降序是2  升序 1,2   销量降序是 3  销量升序是 1
          },
        });

        this.lists[index].data = goods_list;
        this.swiper_right.slideTo(index);
        this.$nextTick(() => {
          this.tab_offsetTop = this.$refs.listData_tab?.offsetTop;
        });
        // this.goods_list = this.goods_lists[index];
      }
    },
    changeActive(item, index) {
      this.active = index;
      this.class_id = this.moData.tab.content[index].key;
      this.swiper_right.slideTo(index);
      // this.getProductList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
#listData {
  width: rem(750);
  margin: 0 auto;
}
.place_area {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99;
  background: #f8f8f8;
  border-bottom: rem(6) solid #f8f8f8;
  .act {
    h2 {
      color: #ff4848;
    }
    p {
      background: #ff4848;
      border-radius: rem(16);
      padding: 0 rem(20);
      color: #ffffff;
      display: inline-block;
      box-sizing: border-box;
      margin: 0 auto;
      height: rem(32);
      line-height: rem(32);
    }
  }

  .li1 {
    display: inline-block;
    text-align: center;
    padding: rem(32) rem(32);
    box-sizing: border-box;
    font-size: rem(28);
    line-height: rem(39);
    // margin-right: rem(64);
    font-style: normal;
    font-weight: 400;

    // margin-right: rem(10);
    position: relative;
    margin-bottom: rem(10);
    color: #666666;
    &:first-of-type {
      padding-left: rem(24);
    }

    .solid {
      position: absolute;
      background: #ff4848;
      border-radius: 0px 0px rem(4) rem(4);
      width: rem(40);
      height: rem(6);
      margin: auto;
      bottom: rem(20);
      left: 0;
      right: 0;
    }
  }
  .span-act {
    color: #ff4848;
    font-weight: 600 !important;
    font-size: rem(32);
    line-height: rem(45);
    // font-weight: bold;
  }
}

.p_product {
  margin-top: rem(30);
  .pp_ul {
    display: flex;
    flex-wrap: wrap;
    width: rem(690);
    margin: 0 auto;
    justify-content: space-between;
    li {
      margin-bottom: rem(16);
      background: #ffffff;
      box-shadow: 0px 0px rem(40) #f5f6fa;
      border-radius: rem(16);
      > img {
        border-radius: rem(16) rem(16) 0px 0px;
        width: rem(337);
        height: rem(337);
      }
      .h1 {
        height: rem(72);
        padding: rem(16);
        overflow: hidden;
        // box-sizing: border-box;
        width: rem(305);
        h1 {
          font-weight: bold;
          font-size: rem(26);
          line-height: rem(36);
          @include ell2;
          color: #222222;
        }
      }
      .data {
        box-sizing: border-box;
        padding: 0 rem(16) rem(20);
        display: flex;
        align-items: center;

        > .price {
          // font-weight: 500;
          // font-family: "price" !important;
          font-family: "price_bold" !important;

          i {
            font-size: rem(22);
          }
          color: #ff4848;
          font-size: rem(30);
          margin-left: rem(8);
        }
        .mark {
          text-decoration-line: line-through;
          color: #c1c1c1;
          font-size: rem(22);
          margin-left: rem(8);
        }
        b {
          border: 1px solid #ff4848;
          border-radius: rem(4);
          box-sizing: border-box;
          padding: 0 rem(8);
          line-height: rem(25);
          color: #ff4848;
          font-size: rem(18);
          height: rem(25);
          text-align: center;
          margin-left: rem(16);
        }
      }
    }
  }
}

.dataModel_type3 {
  padding: 0 rem(24);
  box-sizing: border-box;
  width: 100%;
  .product-list {
    display: flex;
    margin-bottom: rem(16);
    background: #ffffff;
    border-radius: rem(16);
    padding: rem(24);
    align-items: center;
    .product-img {
      width: rem(230);
      height: rem(230);
      border-radius: rem(16);
      margin-right: rem(16);
    }
    .product-info {
      flex: 1;
      .title {
        color: #222222;
        font-weight: 600;
        font-size: rem(30);
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: rem(84);

        .tab {
          position: relative;
          text-align: center;
          height: rem(34);
          box-sizing: border-box;
          padding: rem(3) rem(7);
          background: #f13a4d;
          margin-bottom: rem(10);
          border-radius: rem(2);
          margin-right: rem(6);
          color: #ffffff;
          line-height: rem(34);
          font-weight: 400;
          font-size: rem(20);
          bottom: rem(4);
        }
      }
      .tabs {
        position: relative;
        height: rem(32);
        .sale {
          position: absolute;
          font-weight: 400;
          font-size: rem(24);
          line-height: rem(34);
          right: 0;
          /* identical to box height */

          color: #666666;
        }
        margin-top: rem(17);
        ._item {
          height: rem(32);
          background: #f7f7f7;
          border-radius: rem(4);
          padding: 0px rem(6);
          text-align: center;
          color: #666666;
          font-weight: 400;
          font-size: rem(22);
          line-height: rem(32);
          margin-right: rem(8);
          display: inline-block;
          margin-bottom: rem(4);
        }
      }

      .product_price {
        display: flex;
        margin-top: rem(48);
        align-items: center;
        .nowPrice {
          color: #ff4848;
          // font-weight: 100;
          font-size: rem(30);
          // line-height: rem(32);
          font-family: "price_medium" !important;
          font-weight: 600;
          margin-right: rem(24);
        }
        .linePrice {
          font-weight: 500;
          font-size: rem(24);
          // line-height: rem(29);
          font-family: "price_medium" !important;
          text-decoration-line: line-through;
          color: #cccccc;
          margin-top: rem(4);
        }
      }
      .product_price2 {
        margin-top: rem(32);
        display: flex;
        align-items: center;
        background: url("http://image.shop.19jiang.cn/home/mofang/alioss_627887c340ee0.png")
          no-repeat;
        background-size: 100%;
        // border-radius: rem(8) rem(10) rem(10) rem(8);
        width: 100%;
        height: rem(64);
        box-sizing: border-box;
        padding-left: rem(16);
        .nowPrice {
          color: #fff;
          // font-weight: 700;
          font-size: rem(30);
          // line-height: rem(32);
          font-family: "price_medium" !important;
          font-weight: 600;
          margin-right: rem(8);
        }
        .linePrice {
          font-weight: 500;
          font-size: rem(24);
          // line-height: rem(29);
          font-family: "price_medium" !important;
          text-decoration-line: line-through;
          color: #fd9f9f;
          margin-top: rem(4);
          flex: 1;
        }
        .buy {
          display: flex;
          align-items: center;
          justify-content: center;
          // background: url("https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2022042914253718791.png")
          //   no-repeat;
          width: rem(165);
          // background-size: rem(165) 100%;
          height: 100%;
          color: #ff4848;
          font-weight: 600;
          font-size: rem(30);
          // border-radius: rem(8);
        }
      }
    }
  }
}

.swiper-container-right {
  overflow: hidden;
  width: 100%;
  // height: 100%;
  .swiper-slide {
    height: auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}

.study_ul {
  padding: 0 rem(24);
  width: rem(750);
  box-sizing: border-box;
  margin: 0 auto;
  .study {
    display: flex;
    background: #ffffff;
    border-radius: rem(16);
    padding: rem(24);
    box-sizing: border-box;
    align-items: center;
    margin-bottom: rem(16);
    .mainImage {
      width: rem(230);
      height: rem(203);
      border-radius: rem(8);
      display: block;
    }
    .content {
      flex: 1;
      margin-left: rem(16);
      .title {
        font-weight: 600;
        font-size: rem(30);
        line-height: rem(42);
        color: #222222;
        margin-bottom: rem(8);
        max-height: rem(84);
        overflow: hidden;
      }

      .dsc {
        width: rem(368);
        font-weight: 400;
        font-size: rem(24);
        line-height: rem(34);
        /* identical to box height */
        color: #666666;
        margin-bottom: rem(32);
        @include ell;
      }
      .other {
        display: flex;
        .price {
          display: flex;
          flex: 1;
          align-items: flex-end;
          .now {
            color: #ff4848;
            font-weight: 700;
            font-size: rem(30);
            line-height: rem(32);
          }
          .mark {
            font-weight: 500;
            font-size: rem(24);
            line-height: rem(29);
            text-decoration-line: line-through;
            color: #cccccc;
            margin-left: rem(18);
          }
        }
      }
      .book {
        font-weight: 400;
        font-size: rem(24);
        line-height: rem(34);
        color: #666666;
      }
    }
  }
}
</style>
