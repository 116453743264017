<template>
  <!-- 分割线 -->
  <div
    :style="{
      padding: setRem(getModelIdVal(moData, 'inside')),
      background: getModelIdVal(moData, 'bgcolor'),
    }"
  ></div>
</template>
<script>
import { setRem, getModelPath, getModelIdVal } from "../../js";
export default {
  name: "",
  data: () => ({}),
  created() {
    //do something after creating vue instance
    // console.log(this.moData);
  },
  props: {
    moData: {},
  },
  methods: {
    getModelPath(data) {
      getModelPath(data, this);
    },
    getModelIdVal,
    setRem,
  },
};
</script>
<style lang="scss" scoped>
.mo_image {
  width: 100%;
}
</style>
